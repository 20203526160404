import CameraControls, { CameraControlsView } from "../../camera_controls/camera_controls";
import { Matrix3, Quaternion, Vector2, Vector3 } from "three";
import View from "../../../models/domain/view";

export class CamerasController {

  constructor() {
    this.onOrbitHook = this.copyPhotoCameraToBimCamera.bind(this);
  }

  removeBimControls() {
    this.bimViewerControls = null;
  }

  setCameraViewsOnLoad(view: View) {
    const camera = view.viewAttributes?.camera;
    if (camera && camera.position && camera.target) {
      this.setBimCameraViewWhenExists(camera.position, camera.target);
      this.setPhotoCameraViewWhenExists(camera.position, camera.target);
    }
  }

  setBimCameraViewWhenExists(position: Vector3, target: Vector3) {
    this.bimView = { position, target };
    this.syncBimCameraViewIfExists();
  }

  setPhotoCameraViewWhenExists(position: Vector3, target: Vector3) {
    this.photoView = { position, target };
    this.syncPhotoCameraViewIfExists();
  }

  syncBimCameraViewIfExists() {
    if (this.bimViewerControls && this.bimView) {
      this.bimViewerControls.updateFromView(this.bimView);
      this.bimView = null;
    }
  }

  syncPhotoCameraViewIfExists() {
    if (this.photoViewerControls && this.photoView) {
      this.photoViewerControls.updateFromView(this.photoView);
      this.photoView = null;
    }
  }

  addBimViewerCamera(cameraControls: CameraControls) {
    this.bimViewerControls?.dispose();
    this.bimViewerControls = cameraControls;
    this.syncBimCameraViewIfExists();
  };

  addPhotoViewerCamera(cameraControls: CameraControls) {
    this.photoViewerControls = cameraControls;
    this.syncPhotoCameraViewIfExists();
  };

  copyBimCameraToPhotoCamera() {
    if (this.photoViewerControls && this.bimViewerControls) {
      this.photoViewerControls.syncToCameraControls(this.bimViewerControls);
    }
  };

  copyPhotoCameraToBimCamera() {
    if (this.photoViewerControls && this.bimViewerControls) {
      this.bimViewerControls.syncToCameraControls(this.photoViewerControls);
    }
  };

  enableBimFollowsPhotoCamera() {
    this.copyPhotoCameraToBimCamera();
    if (this.photoViewerControls) {
      this.photoViewerControls.settings.onOrbitHook = this.onOrbitHook;
    }
  };

  disableBimFollowsPhotoCamera() {
    if (this.photoViewerControls) {
      this.photoViewerControls.settings.onOrbitHook = null;
    }
  };

  getBimViewerControls() {
    return this.bimViewerControls
  }

  private bimView: CameraControlsView;
  private photoView: CameraControlsView;
  private readonly onOrbitHook: (event: MouseEvent) => void;

  private bimViewerControls: CameraControls;
  public photoViewerControls: CameraControls;
  public photoAreaMinimapToViewer: Matrix3;
  public updatePhotoCameraCallback: (position: Vector3 | Vector2, orientation: Quaternion) => any;
}


const camerasController = new CamerasController();

export default camerasController;
