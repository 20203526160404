import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type PipelineStepsTriggeredEvent = AvvirEvent<typeof PIPELINE_STEPS_TRIGGERED, { floorId: string, scanDatasetId: string }>

const pipelineStepsTriggered = ({ floorId, scanDatasetId }: AssociationIds): PipelineStepsTriggeredEvent => ({
  type: PIPELINE_STEPS_TRIGGERED,
  payload: {
    floorId,
    scanDatasetId
  }
});

// NOTE: event string literal purposefully left to preserve naming convention in mixpanel
export const PIPELINE_STEPS_TRIGGERED = "superadmin_pipeline_triggered";

export default pipelineStepsTriggered;
