import { ElementEditedEvent } from "../../models/domain/element_edited_event";

import type PlannedBuildingElement from "../../models/domain/planned_building_element";

const elementsStatusesUpdated = (floorId: string, previousElements: PlannedBuildingElement[], nextElements: PlannedBuildingElement[]): ElementEditedEvent => ({
  ...new ElementEditedEvent(ELEMENTS_STATUSES_UPDATED,
    floorId,
    previousElements,
    nextElements)
});

export const ELEMENTS_STATUSES_UPDATED = "elements_statuses_updated";

export default elementsStatusesUpdated;
