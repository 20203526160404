import type { Action } from "redux";

export type ObstructionMarkingFinishedEvent = Action<typeof OBSTRUCTION_MARKING_FINISHED>

const obstructionMarkingFinished = (): ObstructionMarkingFinishedEvent => ({
  type: OBSTRUCTION_MARKING_FINISHED,
});

export const OBSTRUCTION_MARKING_FINISHED = "obstruction_marking_finished";

export default obstructionMarkingFinished;
