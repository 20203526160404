import { AvvirEvent } from "type_aliases";

export type ProcoreCompaniesLoadedEvent = AvvirEvent<typeof PROCORE_COMPANIES_LOADED, any>

const procoreCompaniesLoaded = (procoreCompanies): ProcoreCompaniesLoadedEvent => ({
  type: PROCORE_COMPANIES_LOADED,
  payload: procoreCompanies
});

export const PROCORE_COMPANIES_LOADED = "procore_companies_loaded";

export default procoreCompaniesLoaded;
