import { useFlags as useFlagsOriginal } from "launchdarkly-react-client-sdk";

// eslint-disable-next-line no-undefined
let stubbedFlags = undefined;
// eslint-disable-next-line no-undefined
let stubbedFlagsFunction = undefined;

export const useFlags = () => {
  const actualFlags = useFlagsOriginal();
  if (typeof stubbedFlags !== "undefined") {
    return stubbedFlags;
  } else if (typeof stubbedFlagsFunction !== "undefined") {
    return stubbedFlagsFunction();
  } else {
    return actualFlags;
  }
};

export const stubFlags = (flags: {[key: string]: any}) => {
  stubbedFlags = flags;
  // eslint-disable-next-line no-undefined
  stubbedFlagsFunction = undefined;
};

export const stubFlagsFunction = (func: () => {[key: string]: any}) => {
  stubbedFlagsFunction = func;
  // eslint-disable-next-line no-undefined
  stubbedFlags = undefined;
};
