import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ExportIfcProcessCheckedEvent = AvvirEvent<typeof EXPORT_IFC_PROCESS_CHECKED, {
  processName: string
  ifcType: string
  status: "running" | "complete"
  projectId: string
  floorId: string
  scanDatasetId: string
  startTime: Date
}>

const exportIfcProcessChecked = ({ projectId, floorId, scanDatasetId }: AssociationIds, ifcType: string, processName: string, status: "running" | "complete", startTime: Date): ExportIfcProcessCheckedEvent => ({
  type: EXPORT_IFC_PROCESS_CHECKED,
  payload: {
    processName,
    ifcType,
    status,
    projectId,
    floorId,
    scanDatasetId,
    startTime
  }
});

export const EXPORT_IFC_PROCESS_CHECKED = "export_ifc_process_checked";

export default exportIfcProcessChecked;
