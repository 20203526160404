import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type DefaultScanDatasetForFloorUpdatedEvent = AvvirEvent<typeof DEFAULT_SCAN_DATASET_FOR_FLOOR_UPDATED, {
  floorId: string
  scanDatasetId: string
}>

const defaultScanDatasetForFloorUpdated = ({ floorId, scanDatasetId }: AssociationIds): DefaultScanDatasetForFloorUpdatedEvent => ({
  type: DEFAULT_SCAN_DATASET_FOR_FLOOR_UPDATED,
  payload: {
    floorId,
    scanDatasetId
  }
});

export const DEFAULT_SCAN_DATASET_FOR_FLOOR_UPDATED = "default_scan_dataset_for_floor_updated";

export default defaultScanDatasetForFloorUpdated;
