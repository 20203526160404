import type { ApiInspectReportEntry } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type InspectReportEntryUpdatedEvent = AvvirEvent<typeof INSPECT_REPORT_ENTRY_UPDATED, {
  projectId: string,
  inspectReportId: number,
  inspectReportEntryId: number,
  inspectReportEntry: ApiInspectReportEntry
}>

export const inspectReportEntryUpdated = (projectId: string, inspectReportId: number, inspectReportEntryId: number, inspectReportEntry: ApiInspectReportEntry): InspectReportEntryUpdatedEvent =>({
  type: INSPECT_REPORT_ENTRY_UPDATED,
  payload: {
    projectId,
    inspectReportId,
    inspectReportEntryId,
    inspectReportEntry
  }
})

export const INSPECT_REPORT_ENTRY_UPDATED = "inspect_report_entry_updated"
