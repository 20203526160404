import ColorLib from "tinycolor2";
import CanvasHelper from "./canvas_helper";
import globalStyles from "../../style/global_styles";
import { loadCustomBimColors } from "./store_in_local_storage";

export type LegendItem = { color: ColorLib.ColorInput, text: string };

const fontSize = globalStyles.mediumFontSize;
const radius = fontSize / 2;
const space = globalStyles.space;

export default class LegendHelper extends CanvasHelper {
  customBimColors: boolean = false;
  selectedItems: LegendItem[] = [];
  nonSelectedItems: LegendItem[] = [];
  areAnySelectedElementsObstructing: boolean = false; // Added property

  reset() {
    this.clear();
    this.setTransform();
    this.selectedItems.length = 0;
    this.nonSelectedItems.length = 0;
  }

  addSelectedItem(color: ColorLib.ColorInput, text: string) {
    this.selectedItems.push({ color, text });
  }

  addNonSelectedItem(color: ColorLib.ColorInput, text: string) {
    this.nonSelectedItems.push({ color, text });
  }

  drawLegendBackground(width: number, height: number) {
    this.drawRect(0, 0, width, height, 4, { r: 255, g: 255, b: 255, a: 0.75 });
  }

  drawLegendItem(legendItem: LegendItem, x: number, y: number) {
    this.drawCircle(x + radius, y + radius, radius, legendItem.color);
    this.writeText(legendItem.text, x + radius * 2 + space, y + radius, "middle");
  }

  render = () => {
    this.context.save();
    const { width, height } = this.getSize();

    this.drawLegendBackground(width, height);

    let offsetX = space;
    let offsetY = space;

    this.setTextStyle(globalStyles.mediumGray, fontSize - 6, "Roboto, sans-serif", 400);
    this.writeText("Selected", space, offsetY + radius, "middle");

    this.setTextStyle(globalStyles.mediumGray, fontSize, "Roboto, sans-serif", 400);
    offsetY += space + radius;
    this.selectedItems.forEach((legendItem) => {
      this.drawLegendItem(legendItem, offsetX, offsetY);
      offsetY += fontSize + space;
    });

    if (this.areAnySelectedElementsObstructing) {
      this.setTextStyle(globalStyles.mediumGray, fontSize, "Roboto, sans-serif", 400);
      offsetY += space;

      this.drawLine(offsetX, offsetY, 100, offsetY, globalStyles.darkGray);

      offsetY += space;
      this.setTextStyle(globalStyles.mediumGray, fontSize - 6, "Roboto, sans-serif", 400);
      this.writeText("Non-Selected", space, offsetY + radius, "middle");
      offsetY += space * 2;
      this.setTextStyle(globalStyles.mediumGray, fontSize, "Roboto, sans-serif", 400);
      this.nonSelectedItems.forEach((legendItem) => {
        this.drawLegendItem(legendItem, offsetX, offsetY);
        offsetY += fontSize + space;
        if (legendItem.text === "Obstructed") {
          this.setTextStyle(globalStyles.mediumGray, fontSize - 6, "Roboto, sans-serif", 400);
          // we want to tuck this under the last item
          offsetY += 0.1;
          this.writeText("Not Built", offsetX + radius * 2 + space, offsetY, "middle");
          this.setTextStyle(globalStyles.mediumGray, fontSize, "Roboto, sans-serif", 400);
        }
      });
    }

    this.context.restore();
  };

  getSize = () => {
    this.context.save();
    this.setTextStyle(globalStyles.mediumGray, fontSize, "Roboto, sans-serif", 400);
    let maxLineWidth = 0;

    this.selectedItems.forEach((legendItem) => {
      const lineWidth = space + radius * 2 + space + this.context.measureText(legendItem.text).width + space;
      maxLineWidth = Math.max(maxLineWidth, lineWidth);
    });

    if (this.areAnySelectedElementsObstructing) {
      this.nonSelectedItems.forEach((legendItem) => {
        const lineWidth = space + radius * 2 + space + this.context.measureText(legendItem.text).width + space;
        maxLineWidth = Math.max(maxLineWidth, lineWidth);
      });
    }

    const totalRows =
      this.selectedItems.length +
      (this.areAnySelectedElementsObstructing ? this.nonSelectedItems.length : 0);
    const spacers = this.areAnySelectedElementsObstructing ? 8 : 2;
    const height =
      space +
      totalRows * (fontSize + space) +
      space * spacers;

    this.context.restore();
    return { width: maxLineWidth, height };
  };

  drawLegendForScreenshot() {
    this.reset();
    if (this.customBimColors) {
      const colors = loadCustomBimColors();
      this.addSelectedItem(globalStyles.selectionBlue, "As-Designed");
      this.addSelectedItem(colors.AsBuilt, "As-Built");
      this.addNonSelectedItem(colors.NotBuilt, "Not-Built");
      this.addNonSelectedItem(colors.InPlace, "Built");
      this.addNonSelectedItem(colors.DeviationAsDesigned, "Deviated");
      this.addNonSelectedItem(colors.ClashingAsDesigned, "Clashing");
      this.addNonSelectedItem(colors.Obstructed, "Obstructed");
    } else {
      this.addSelectedItem(globalStyles.selectionBlue, "As-Designed");
      this.addSelectedItem(globalStyles["BIM/As Built"], "As-Built");
      this.addNonSelectedItem(globalStyles["BIM/Not Built"], "Not-Built");
      this.addNonSelectedItem(globalStyles["BIM/Built"], "Built");
      this.addNonSelectedItem(globalStyles["BIM/Deviation"], "Deviated");
      this.addNonSelectedItem(globalStyles["BIM/Clash"], "Clashing");
      this.addNonSelectedItem(globalStyles["BIM/Obstructed"], "Obstructed");
    }
    this.areAnySelectedElementsObstructing = true;
    const { width, height } = this.getSize();
    this.resize(width + globalStyles.space, height + globalStyles.space);
    this.render();
  }
}