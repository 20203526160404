import { isEqual } from "underscore";

export interface CustomBimColors {
  InPlace: string;
  AsBuilt: string;
  DeviationAsDesigned: string;
  ClashingAsDesigned: string;
  Included: string;
  NotBuilt: string;
  Obstructed: string
}

export interface RgbColor {
  r: number;
  g: number;
  b: number;
}

export function defaultBimColors(): CustomBimColors {
  return {
    InPlace: "#616161",
    AsBuilt: "#19dada",
    DeviationAsDesigned: "#ffb74d",
    ClashingAsDesigned: "#f44336",
    Included: "#872fd2",
    NotBuilt: "#d6c9b3",
    Obstructed: "#f44336"
  };
}

export function setForgeBimColors(colors: CustomBimColors) {
  const changeColors = window.Avvir.changeBimColor;
  changeColors.InPlace(colors.InPlace);
  changeColors.AsBuilt(colors.AsBuilt);
  changeColors.DeviationAsDesigned(colors.DeviationAsDesigned);
  changeColors.ClashingAsDesigned(colors.ClashingAsDesigned);
  changeColors.Included(colors.Included);
  changeColors.NotBuilt(colors.NotBuilt);
  changeColors.Obstructed(colors.Obstructed);
}

export function areBimColorsDefault(colors: CustomBimColors) {
  return isEqual(defaultBimColors(), colors);
}

export function hexToRgb(hex: string): RgbColor {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : { r: 0, g: 0, b: 0 };
}

// from http://www.w3.org/TR/WCAG20/#relativeluminancedef
export function relativeLuminanceW3C(color: RgbColor) {
  const RsRGB = color.r / 255;
  const GsRGB = color.g / 255;
  const BsRGB = color.b / 255;

  const R = (RsRGB <= 0.03928) ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4);
  const G = (GsRGB <= 0.03928) ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4);
  const B = (BsRGB <= 0.03928) ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4);

  // For the sRGB colorspace, the relative luminance of a color is defined as:
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}