import loadFloorPipelines from "../superadmin/pages/superadmin_project_page/superadmin_floor/load_floor_pipelines";
import RunningProcessStatus from "../../models/domain/enums/running_process_status";
import runPipeline from "../run_pipeline";

import type { ApiFailureEvent } from "../../events/notifications/failures/api_failure";
import type { ApiPipeline, AssociationIds } from "avvir";
import type { AvvirThunk } from "../make_eventful_action";

type ReturnThunk = AvvirThunk<Promise<ApiPipeline>, ApiFailureEvent>;


export default function triggerQaStatisticsPipeline({ projectId, floorId, scanDatasetId }: AssociationIds): ReturnThunk {
  return (dispatch, getState) => {
    return dispatch(loadFloorPipelines(floorId)).then(pipelines => {
      let runningQaPipeline = pipelines.find(pipeline => {
        return pipeline.name === "compute-qa-statistics" &&
               pipeline.firebaseScanDatasetId === scanDatasetId &&
               pipeline.status === RunningProcessStatus.RUNNING;
      });
      if (runningQaPipeline == null) {
        return runPipeline({
          name: "compute-qa-statistics",
          firebaseProjectId: projectId,
          firebaseFloorId: floorId,
          firebaseScanDatasetId: scanDatasetId,
        })(dispatch, getState);
      } else {
        return null;
      }
    });
  };
}
