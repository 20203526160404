import React, { FunctionComponent, MouseEvent, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button as MuiButton, IconButton, List, ListItem, Popover } from "@mui/material";
import { ArrowCircleDown, ArrowCircleUp, ArrowDropDown, Circle, CircleOutlined } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";
import _ from "underscore";
import classNames from "classnames";
import { useFlags } from "../../../services/utilities/launch_darkly_helper";
import { ApiScanDatasetQaState } from "avvir";

import "../../../../css/nav_bar/nav_bar_qa_status_selector.css";
import getFloorId from "../../../services/getters/floor_getters/get_floor_id";
import getProjectId from "../../../services/getters/project_getters/get_project_id";
import getProjectSettings from "../../../services/getters/project_getters/get_project_settings";
import getScanDatasetId from "../../../services/getters/scan_dataset_getters/get_scan_dataset_id";
import ScanDataset from "../../../models/domain/scan_dataset";
import Tooltipable from "../../shared/tooltipable";
import triggerQaStatisticsPipeline from "../../../actions/nav_bar/trigger_qa_statistics_pipeline";
import { autoCalculateProgress } from "../../../actions/progress_report_page/auto_calculate_progress";
import updateScanDatasetQaState from "../../../actions/nav_bar/update_scan_dataset_qa_state";
import usePopover from "../../../services/component_helpers/component_effects/use_popover";
import useScanDataset from "../../../queries/scan_datasets/use_scan_dataset";

import type { Dispatch } from "type_aliases";
import updateDefaultScanDatasetForFloor from "../../../actions/superadmin/pages/superadmin_project_page/superadmin_floor/update_default_scan_dataset_for_floor";

const Button = withStyles(() => ({
  endIcon: {
    marginLeft: 0
  }
}))(MuiButton);
export const qaStatuses = [{
  label: "Pending",
  value: ApiScanDatasetQaState.NO_DATA,
  color: "#757575"
}, {
  label: "Ready",
  value: ApiScanDatasetQaState.NOT_STARTED,
  color: "#ff8b25"
}, {
  label: "In Progress",
  value: ApiScanDatasetQaState.STARTED,
  color: "#fffe3e"
}, {
  label: "Completed",
  value: ApiScanDatasetQaState.COMPLETED,
  color: "#00ff3c"
}];

const qaStatusOccurred = (status: ApiScanDatasetQaState | "ready", scanDataset: ScanDataset) => {
  if (status === scanDataset?.qaState) {
    return false;
  }
  switch (scanDataset?.qaState) {
    case ApiScanDatasetQaState.COMPLETED: {
      return true;
    }
    case ApiScanDatasetQaState.STARTED: {
      return status !== ApiScanDatasetQaState.COMPLETED;
    }
    case ApiScanDatasetQaState.NOT_STARTED: {
      return status === ApiScanDatasetQaState.NO_DATA;
    }
    case ApiScanDatasetQaState.NO_DATA: {
      return false;
    }
  }
};

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const NavBarQaStatusSelector: FunctionComponent<Props> = function NavBarQaStatusSelector(props) {
  const { projectId, floorId, scanDatasetId, updateScanDatasetQaState, triggerQaStatisticsPipeline, autoCalculateProgress, projectSettings, updateDefaultScan } = props;
  const { autoCalculateProgressToggle, setDefaultScan } = useFlags();
  const { data: scanDataset } = useScanDataset(projectId, floorId, scanDatasetId);
  const [openState, anchorEl, onOpen, onClose] = usePopover();

  const isScanDatasetQAStarted = scanDataset?.qaState === ApiScanDatasetQaState.STARTED;
  const isAutoCalculateProgressEnabled = autoCalculateProgressToggle && projectSettings?.autoCalculateProgress;

  const onChangeStateClick = useCallback(async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const datasetType = event.currentTarget.dataset.type;
      const datasetState = event.currentTarget.dataset.value as ApiScanDatasetQaState;
      const isNextState = datasetType === "next";

      if (isNextState) {
        await updateScanDatasetQaState({ projectId, floorId, scanDatasetId }, ScanDataset.getNextQaState(datasetState));
      } else {
        await updateScanDatasetQaState({ projectId, floorId, scanDatasetId }, ScanDataset.getPreviousQaState(datasetState));
      }

      if (isScanDatasetQAStarted) {
        await triggerQaStatisticsPipeline({ projectId, floorId, scanDatasetId });
      }

      if (isAutoCalculateProgressEnabled && isScanDatasetQAStarted) {
        await autoCalculateProgress({ projectId, floorId });
      }

      if (setDefaultScan && isNextState && datasetState === ApiScanDatasetQaState.STARTED) {
        await updateDefaultScan({ projectId, floorId, scanDatasetId });
      }
    },
    [isScanDatasetQAStarted,
      isAutoCalculateProgressEnabled,
      setDefaultScan,
      updateScanDatasetQaState,
      projectId,
      floorId,
      scanDatasetId,
      triggerQaStatisticsPipeline,
      autoCalculateProgress,
      updateDefaultScan
    ]);

  const statuses = qaStatuses.map((status) => {
    let icon = <Circle className="NavBarQaStatusSelector-icon" style={{ color: status.color, strokeWidth: 0.25, stroke: "#515151" }}/>;

    if (!qaStatusOccurred(status.value, scanDataset) && status.value !== scanDataset?.qaState) {
      icon = <CircleOutlined className="NavBarQaStatusSelector-icon"/>;
    }

    return <ListItem
      key={`NavBarQaStatusSelector-status-${status.value}`}
      value={status.value}
      className={classNames("NavBarQaStatusSelector-statusOption", {
        "NavBarQaStatusSelector-statusOption-selected": status.value === scanDataset?.qaState
      })}
      disableGutters
      selected={status.value === scanDataset?.qaState}
    >
      {icon}
      {status.label}
      <div
        className={classNames("NavBarQaStatusSelector-changeStatusButtons", {
          "NavBarQaStatusSelector-changeStatusButtons-hidden": status.value !== scanDataset?.qaState
        })}
      >
        <IconButton
          data-value={status.value}
          data-type="previous"
          className="NavBarQaStatusSelector-button NavBarQaStatusSelector-previousStateButton"
          disabled={status.value === ApiScanDatasetQaState.NO_DATA || status.value === ApiScanDatasetQaState.NOT_STARTED}
          onClick={onChangeStateClick}
        ><ArrowCircleUp/></IconButton>
        <IconButton
          data-value={status.value}
          data-type="next"
          className="NavBarQaStatusSelector-button NavBarQaStatusSelector-nextStateButton"
          disabled={status.value === ApiScanDatasetQaState.COMPLETED}
          onClick={onChangeStateClick}
        ><ArrowCircleDown/></IconButton>
      </div>
    </ListItem>;
  });
  const currentStatus = _.findWhere(qaStatuses, { value: scanDataset?.qaState });
  return <>
    <Tooltipable title={currentStatus?.label}>
      <Button
        className="NavBarDropdown-button NavBarQaStatusSelector-button"
        endIcon={<ArrowDropDown className="NavBarQaStatusSelector-dropdownIcon"/>}
        onClick={onOpen}
        aria-haspopup="true"
        {...openState && {
          "aria-controls": "scan-datasets-qa-status-menu",
          "aria-expanded": "true"
        }}
        aria-label="Change QA Status"
      >
        <Circle className="NavBarQaStatusSelector-icon" style={{ color: currentStatus?.color, strokeWidth: 0.25, stroke: "#515151" }}/>
      </Button>
    </Tooltipable>
    <Popover
      className="NavBarQaStatusSelector"
      open={openState}
      anchorEl={anchorEl}
      id="scan-datasets-qa-status-menu"
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
    >
      <List>
        {statuses}
      </List>
    </Popover>
  </>;
};

const mapStateToProps = (state, props) => ({
  projectId: getProjectId(state, props),
  floorId: getFloorId(state, props),
  scanDatasetId: getScanDatasetId(state, props),
  projectSettings: getProjectSettings(state, props)
});

type DispatchedEvents = Dispatch<ReturnType<typeof updateScanDatasetQaState | typeof triggerQaStatisticsPipeline | typeof autoCalculateProgress | typeof updateDefaultScanDatasetForFloor>>

const mapDispatchToProps = (dispatch: DispatchedEvents) => bindActionCreators({
  updateScanDatasetQaState,
  triggerQaStatisticsPipeline,
  autoCalculateProgress,
  updateDefaultScan: updateDefaultScanDatasetForFloor
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavBarQaStatusSelector);
