import Avvir, { ApiFloor } from "avvir";

import apiFailure, { ApiFailureEvent } from "../../../../../events/notifications/failures/api_failure";
import defaultScanDatasetForFloorUpdated, { DefaultScanDatasetForFloorUpdatedEvent } from "../../../../../events/superadmin/scan_datasets/default_scan_dataset_for_floor_updated";
import getFloor from "../../../../../services/getters/floor_getters/get_floor";

import type Floor from "../../../../../models/domain/floor";
import type { AssociationIds } from "avvir";
import type { AvvirThunk } from "../../../../make_eventful_action";

const updateDefaultScanDatasetForFloor = ({
  projectId,
  floorId,
  scanDatasetId
}: AssociationIds): AvvirThunk<Promise<void>, DefaultScanDatasetForFloorUpdatedEvent | ApiFailureEvent> => {
  return (dispatch, getState) => {
    const user = getState().user;
    const floor = getFloor(getState(), { projectId, floorId });
    const apiFloor = new ApiFloor(floor as Floor);
    apiFloor.defaultFirebaseScanDatasetId = scanDatasetId;

    return Avvir.api.floors.updateFloor({ projectId, floorId }, apiFloor, user)
      .then(() => {
        dispatch(defaultScanDatasetForFloorUpdated({ floorId, scanDatasetId }));
      })
      .catch(error => {
        dispatch(apiFailure(error))
      });
  };
};

export default updateDefaultScanDatasetForFloor;
