import type { AvvirEvent } from "type_aliases";

export type ElementsVerifiedUpdatedEvent = AvvirEvent<typeof ELEMENTS_VERIFIED_UPDATED, { firebaseFloorId: string, elementsGlobalIds: string[], verifiedStatus: boolean }>

const elementsVerifiedUpdated = (firebaseFloorId: string, elementsGlobalIds: string[], verifiedStatus: boolean): ElementsVerifiedUpdatedEvent => ({
  type: ELEMENTS_VERIFIED_UPDATED,
  payload: {
    firebaseFloorId,
    elementsGlobalIds,
    verifiedStatus
  }
});

export const ELEMENTS_VERIFIED_UPDATED = "elements_verified_updated";

export default elementsVerifiedUpdated;
