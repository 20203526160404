import type ProjectReportVersion from "../../../models/domain/project_report_version";
import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ProjectReportVersionUpdatedEvent = AvvirEvent<typeof PROJECT_REPORT_VERSION_UPDATED, {
  projectId: string,
  projectReportId: number,
  reportVersion: ProjectReportVersion
}>

const projectReportVersionUpdated = ({ projectId, projectReportId }: AssociationIds & { projectReportId?: number }, reportVersion: ProjectReportVersion): ProjectReportVersionUpdatedEvent => ({
  type: PROJECT_REPORT_VERSION_UPDATED,
  payload: {
    projectId,
    projectReportId,
    reportVersion
  }
});

export const PROJECT_REPORT_VERSION_UPDATED = "project_report_version_updated";

export default projectReportVersionUpdated;
