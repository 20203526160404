import { AvvirEvent } from "type_aliases";

export type AutodeskProjectSelectedEvent = AvvirEvent<typeof AUTODESK_PROJECT_SELECTED, number>

const autodeskProjectSelected = (projectId: number): AutodeskProjectSelectedEvent => ({
  type: AUTODESK_PROJECT_SELECTED,
  payload: projectId
});

export const AUTODESK_PROJECT_SELECTED = "autodesk_project_selected";

export default autodeskProjectSelected;