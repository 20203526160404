import React, { FunctionComponent, useCallback, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MenuItem } from "@mui/material";
import { useFlags } from "../../services/utilities/launch_darkly_helper";

import "../../../css/nav_bar/nav_bar_panels_menu.css";
import CheckboxMenuItem from "../general/checkbox_menu_item";
import getPageName from "../../services/getters/location_metadata/get_page_name";
import getProjectId from "../../services/getters/project_getters/get_project_id";
import getVisiblePanels from "../../services/getters/selection_getters/get_visible_panels";
import NavBarDropdown from "./nav_bar_dropdown";
import panelHidden from "../../events/selection/panel_hidden";
import panelNodesChanged from "../../events/selection/panel_nodes_changed";
import panelShown from "../../events/selection/panel_shown";
import panelsReset from "../../events/selection/panels_reset";
import useIsMobileView from "../../services/component_helpers/component_effects/use_is_mobile_view";
import usePopover from "../../services/component_helpers/component_effects/use_popover";
import useProject from "../../queries/projects/use_project";
import { getEnabledPanelTypesByPageAndFlags, getPanelNodesByPanelType, PanelType } from "../../models/domain/enums/panel_type";

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const NavBarPanelsMenu: FunctionComponent<Props> = (props) => {
  const { pageName, visiblePanels, panelHidden, panelShown, projectId, panelsReset } = props;
  const {
    groupsPanel,
    actionHistoryPanel,
    customReports,
    resetPanels,
    showAllReportsOnSummary
  } = useFlags();
  const { data: project } = useProject(projectId);
  const [openState, anchorEl, handleOpen, onClose] = usePopover();
  const photosOnly = project?.settings?.photosOnly;
  const progressReportTab = project?.settings?.progress4d;
  const projectAreasModelViewerPanel = project?.settings?.projectSummaryPage || !customReports;
  const projectAreaSummaryPanel = project?.settings?.projectSummaryPage || !customReports;
  const showCustomReportsOnSummary = showAllReportsOnSummary && project?.projectReports?.filter(report => report.type !== "INSPECT").length > 0;
  const showProgressOnSummary = project?.settings?.progress4d && showAllReportsOnSummary;
  const isUserOnMobile = useIsMobileView();
  const projectMetrics = project?.settings?.inspect || project?.settings?.progress4d || project?.settings?.progress5d;

  const panelTypes = useMemo(() => {
    return getEnabledPanelTypesByPageAndFlags(pageName, {
      projectMetrics,
      groupsPanel,
      actionHistoryPanel,
      customReportsPanel: customReports,
      photosOnly,
      projectAreasModelViewerPanel,
      projectAreaSummaryPanel,
      desktopOnly: !isUserOnMobile,
      progressReportTab,
      showProgressOnSummary,
      showCustomReportsOnSummary,
    });
  }, [pageName, projectMetrics, groupsPanel, actionHistoryPanel, customReports, photosOnly, projectAreasModelViewerPanel, projectAreaSummaryPanel, isUserOnMobile, progressReportTab, showProgressOnSummary, showCustomReportsOnSummary]);

  const onReset = useCallback(() => {
    panelsReset(pageName);
    if (window.Avvir?.resetPanels) {
      window.Avvir.resetPanels();
    }
    onClose();
  }, [onClose, pageName, panelsReset]);

  const panelOptionsMarkup = useMemo(() => {
    return panelTypes.map(getPanelNodesByPanelType)
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
      .map(node => {
        const panelType = node.component;
        return <CheckboxMenuItem<PanelType>
          key={"panel-" + panelType}
          value={panelType}
          enabled={visiblePanels.includes(panelType)}
          onItemEnable={(value) => {
            panelShown(pageName, value);
          }}
          onItemDisable={(value) => {
            panelHidden(pageName, value);
          }}
          label={node.name}
        />;
      });
  }, [pageName, panelHidden, panelShown, panelTypes, visiblePanels]);

  return <NavBarDropdown
    className="NavBarPanelsMenu-button"
    label="Panels"
    popoverProps={{
      open: openState,
      anchorEl,
      handleOpen,
      onClose
    }}
    slotProps={{
      menu: {
        className: "NavBarPanelsMenu"
      }
    }}
  >
    {panelOptionsMarkup}
    {resetPanels ?
    <MenuItem key="panels-reset" onClick={onReset}>
      Reset Panels to Default Layout
    </MenuItem> : null}
  </NavBarDropdown>;
};

const mapStateToProps = (state, props) => ({
  visiblePanels: getVisiblePanels(state, props),
  pageName: getPageName(state, props),
  projectId: getProjectId(state, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  panelNodesChanged,
  panelHidden,
  panelShown,
  panelsReset
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavBarPanelsMenu);
