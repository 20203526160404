import makeUpdateModel from "./make_update_model";
import Recipe from "../../../models/domain/recipe";
import { PROJECT_RECIPE_CREATED, ProjectRecipeCreatedEvent } from "../../../events/recipes/project_recipe_created";
import { PROJECT_RECIPE_REMOVED, ProjectRecipeRemovedEvent } from "../../../events/recipes/project_recipe_removed";
import { PROJECT_RECIPES_LOADED, ProjectRecipesLoadedEvent } from "../../../events/loaded/project_recipes_loaded";
import { PROJECT_RECIPES_UPDATED, ProjectRecipesUpdatedEvent } from "../../../events/recipes/project_recipes_updated";
import { RECIPE_UPDATED, RecipeUpdatedEvent } from "../../../events/recipes/recipe_updated";

import type { Reducer } from "redux";

type RecipeEvents =
  | ProjectRecipesLoadedEvent
  | ProjectRecipeCreatedEvent
  | ProjectRecipeRemovedEvent
  | ProjectRecipesUpdatedEvent
  | RecipeUpdatedEvent;

export type RecipesStore = {
  byId: {
    [id: number]: Recipe
  },
}

const updateRecipe = makeUpdateModel(new Recipe({}));

const copyRecipeStore = (store: RecipesStore) => {
  return {
    byId: { ...store.byId },
  };
};

const reduceRecipes: Reducer<RecipesStore, RecipeEvents> = (recipesStore = { byId: {} }, event) => {
  switch (event.type) {
    case PROJECT_RECIPES_LOADED: {
      return event.payload.recipes.reduce((recipesSoFar, recipe) => {
        return updateRecipe(recipesSoFar, recipe.id, recipe);
      }, recipesStore);
    }
    case PROJECT_RECIPE_CREATED: {
      let { recipe } = event.payload;
      return updateRecipe(recipesStore, recipe.id, recipe);
    }
    case PROJECT_RECIPES_UPDATED: {
      return event.payload.recipes.reduce((recipesSoFar, recipe) => {
        return updateRecipe(recipesSoFar, recipe.id, recipe);
      }, recipesStore);
    }
    case PROJECT_RECIPE_REMOVED: {
      let { id } = event.payload;
      const newRecipeStore = copyRecipeStore(recipesStore);
      const recipe = newRecipeStore.byId[id];
      if (recipe) {
        delete newRecipeStore.byId[id];
      }
      return newRecipeStore;
    }
    case RECIPE_UPDATED: {
      return updateRecipe(recipesStore, event.payload.recipe.id, event.payload.recipe);
    }
    default: {
      return recipesStore;
    }
  }
};

export default reduceRecipes;
