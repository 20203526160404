import type TeamMember from "../../models/domain/team_member";
import type { AvvirEvent } from "type_aliases";

export type TeamMembersCreatedEvent = AvvirEvent<typeof TEAM_MEMBERS_CREATED, { projectId: string, teamMembers: TeamMember[] }>

const teamMembersCreated = (projectId: string, teamMembers: TeamMember[]): TeamMembersCreatedEvent => ({
  type: TEAM_MEMBERS_CREATED,
  payload: {
    projectId,
    teamMembers
  }
});

export const TEAM_MEMBERS_CREATED = "team_members_created";

export default teamMembersCreated;
