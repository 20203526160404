import type { AssociationIds } from "avvir";
import Avvir from "avvir";

import getScanDatasetsForFloorByFirebaseId from "../../../services/getters/scan_dataset_getters/get_scan_datasets_for_floor_by_firebase_id";
import scanDatasetsForFloorLoaded, { ScanDatasetsForFloorLoadedEvent } from "../../../events/loaded/scan_datasets/scan_datasets_for_floor_loaded";
import type { AvvirAsyncThunk } from "../../make_eventful_action";

const loadScanDatasetsForFloor = ({ projectId, floorId }: AssociationIds): AvvirAsyncThunk<typeof getScanDatasetsForFloorByFirebaseId, ScanDatasetsForFloorLoadedEvent> => {
  return (dispatch, getState) => {
    const state = getState();
    return Avvir.api.scanDatasets.listScanDatasetsForFloor({ projectId, floorId }, state.user).then(scanDatasets => {
      dispatch(scanDatasetsForFloorLoaded(floorId, scanDatasets));
      return getScanDatasetsForFloorByFirebaseId(getState(), { projectId, floorId });
    });
  };
};

export default loadScanDatasetsForFloor;
