import React, { useCallback } from "react";
import { useFlags } from "../../../services/utilities/launch_darkly_helper";
import { MenuItem } from "@mui/material";


function ResourceHubLink() {
  let { resourceHubNavBarLink } = useFlags();

  let openResourceHub = useCallback(function () {
    let resourceHubUrl = "https://buildings-hexagon-customerhub.super.site/";
    window.open(resourceHubUrl, "_blank");
  }, []);
  return <>{resourceHubNavBarLink ? <MenuItem onClick={openResourceHub}>Support Center</MenuItem> : null}</>;
}

export default React.memo(ResourceHubLink);
