import { Action } from "redux";

export type AutodeskIssueCreationDialogDismissedEvent = Action<typeof AUTODESK_ISSUE_CREATION_DIALOG_DISMISSED>

const autodeskIssueCreationDialogDismissed = (): AutodeskIssueCreationDialogDismissedEvent => ({
  type: AUTODESK_ISSUE_CREATION_DIALOG_DISMISSED
});

export const AUTODESK_ISSUE_CREATION_DIALOG_DISMISSED = "autodesk_issue_creation_dialog_dismissed";

export default autodeskIssueCreationDialogDismissed;
