import PlannedBuildingElement from "../../models/domain/planned_building_element";
import { AvvirEvent } from "type_aliases";

export type AutodeskIssueCreationDialogShownEvent = AvvirEvent<typeof AUTODESK_ISSUE_CREATION_DIALOG_SHOWN, { globalId: string, selectedElement: PlannedBuildingElement }>

const autodeskIssueCreationDialogShown = (globalId: string, selectedElement: PlannedBuildingElement): AutodeskIssueCreationDialogShownEvent => ({
  type: AUTODESK_ISSUE_CREATION_DIALOG_SHOWN,
  payload: {
    globalId,
    selectedElement,
  }
});

export const AUTODESK_ISSUE_CREATION_DIALOG_SHOWN = "autodesk_issue_creation_dialog_shown";

export default autodeskIssueCreationDialogShown;
