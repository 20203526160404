import { immerable } from "immer";

export default class TeamMember {
  id?: number;
  name: string;
  userOrganization: string;
  email: string;
  role: string;
  masterformats?: string[];
  workPackageIds?: number[];

  constructor({ id, name, userOrganization, email, role, masterformats, workPackageIds }: Partial<TeamMember> = {}) {
    this.id = id;
    this.name = name;
    this.userOrganization = userOrganization;
    this.email = email;
    this.role = role;
    this.masterformats = masterformats;
    this.workPackageIds = workPackageIds;
  }

  static readonly [immerable] = true;
}
