export enum InspectionMode {
  basicBim = "fullBim",
  bimPrep = "bimPrep",
  inspect = "qualityControlBim",
  progress = "monitorBim",
}

export interface InspectionModeOption {
  displayName: string,
  legacyName: string,
  value: InspectionMode
}

// NOTE(dlb): This affects the order that the inspection modes are displayed in the UI.
const inspectionModes: Array<InspectionModeOption> = [
  {
    displayName: "Visual Progress",
    legacyName: "Basic BIM",
    value: InspectionMode.basicBim
  },
  {
    displayName: "BIM Prep",
    legacyName: "BIM Prep",
    value: InspectionMode.bimPrep
  },
  {
    displayName: "Deviation Analysis",
    legacyName: "Inspect",
    value: InspectionMode.inspect
  },
  {
    displayName: "Progress Reporting",
    legacyName: "Progress",
    value: InspectionMode.progress
  },
];

export default inspectionModes;
