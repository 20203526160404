import { AvvirEvent } from "type_aliases";

export type ProcoreAssigneeSelectedEvent = AvvirEvent<typeof PROCORE_ASSIGNEE_SELECTED, any>

const procoreAssigneeSelected = (selectedProcoreAssigneeId): ProcoreAssigneeSelectedEvent => ({
  type: PROCORE_ASSIGNEE_SELECTED,
  payload: selectedProcoreAssigneeId
});

export const PROCORE_ASSIGNEE_SELECTED = "procore_assignee_selected";

export default procoreAssigneeSelected;
