import { AvvirEvent } from "type_aliases";

export type ProcoreCompanySelectedEvent = AvvirEvent<typeof PROCORE_COMPANY_SELECTED, any>

const procoreCompanySelected = (selectedProcoreCompanyId): ProcoreCompanySelectedEvent => ({
  type: PROCORE_COMPANY_SELECTED,
  payload: selectedProcoreCompanyId
});

export const PROCORE_COMPANY_SELECTED = "procore_company_selected";

export default procoreCompanySelected;
