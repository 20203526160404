import { ElementEditedEvent } from "../../models/domain/element_edited_event";

import type PlannedBuildingElement from "../../models/domain/planned_building_element";

const elementsExcludeFromAnalysisUpdated = (floorId: string, previousElements: PlannedBuildingElement[], nextElements: PlannedBuildingElement[]): ElementEditedEvent => ({
  ...new ElementEditedEvent(ELEMENTS_EXCLUDE_FROM_ANALYSIS_UPDATED,
    floorId,
    previousElements,
    nextElements)
});

export const ELEMENTS_EXCLUDE_FROM_ANALYSIS_UPDATED = "elements_exclude_from_analysis_updated";

export default elementsExcludeFromAnalysisUpdated;
