import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type PipelineStartedEvent = AvvirEvent<typeof PIPELINE_STARTED, {
  floorId: string,
  scanDatasetId: string,
  data: object
}>

const pipelineStarted = ({ floorId, scanDatasetId }: AssociationIds, data: object): PipelineStartedEvent => ({
  type: PIPELINE_STARTED,
  payload: {
    floorId,
    scanDatasetId,
    data
  }
});

export const PIPELINE_STARTED = "pipeline_started";

export default pipelineStarted;
