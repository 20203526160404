import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type UpdateProjectMasterformatProgressStartedEvent = AvvirEvent<typeof UPDATE_PROJECT_MASTERFORMAT_PROGRESS_STARTED, {
  processId: number
  projectId: string
  startTime: Date
}>

const updateProjectMasterformatProgressStarted = ({ projectId }: AssociationIds, startTime: Date, processId: number): UpdateProjectMasterformatProgressStartedEvent => ({
  type: UPDATE_PROJECT_MASTERFORMAT_PROGRESS_STARTED,
    payload: {
      processId,
      projectId,
      startTime
    }
});

export const UPDATE_PROJECT_MASTERFORMAT_PROGRESS_STARTED = "update_project_masterformat_progress_started";

export default updateProjectMasterformatProgressStarted;
