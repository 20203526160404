import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type UpdateProjectMasterformatProgressCompletedEvent = AvvirEvent<typeof UPDATE_PROJECT_MASTERFORMAT_PROGRESS_COMPLETED,{
  processId: number
  projectId: string
  startTime: Date
}>

const updateProjectMasterformatProgressCompleted = ({ projectId }: AssociationIds, startTime: Date, processId: number): UpdateProjectMasterformatProgressCompletedEvent => ({
  type: UPDATE_PROJECT_MASTERFORMAT_PROGRESS_COMPLETED,
  payload: {
    processId,
    projectId,
    startTime,
},
});

export const UPDATE_PROJECT_MASTERFORMAT_PROGRESS_COMPLETED = "update_project_masterformat_progress_completed";

export default updateProjectMasterformatProgressCompleted;
