import type { AvvirEvent } from "type_aliases";

export type InspectReportDeletedEvent = AvvirEvent<typeof INSPECT_REPORT_DELETED, { inspectReportId: number }>

const inspectReportDeleted = (inspectReportId: number) =>({
  type: INSPECT_REPORT_DELETED,
  payload: {
    inspectReportId
  }
})

export const INSPECT_REPORT_DELETED = "inspect_report_deleted";

export default inspectReportDeleted;
