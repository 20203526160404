import Avvir from "avvir";

import apiFailure from "../../events/notifications/failures/api_failure";
import buildFileName from "../../services/file_name_helpers/build_file_name";
import getFloorId from "../../services/getters/floor_getters/get_floor_id";
import getFloorNumber from "../../services/getters/floor_getters/get_floor_number";
import getProjectIdFromLocation from "../../services/getters/location_metadata/get_project_id_from_location";
import getProjectName from "../../services/getters/project_getters/get_project_name";
import getUser from "../../services/getters/base_getters/get_user";
import triggerFileDownload from "../../services/utilities/general/trigger_file_download";

const downloadDeviatedTsv = () => {
  return (dispatch, getState) => {
    const state = getState();
    const projectName = getProjectName(state, {});
    const floorNumber = getFloorNumber(state, {});
    const projectId = getProjectIdFromLocation(state, {});
    const floorId = getFloorId(state, {});
    const user = getUser(state, {});

    const deviationsReportFileName = buildFileName(null, { projectName, floorNumber });
    const reportUrl = Avvir.api.other.getDeviationsReportTsvUrl(
      { projectId, floorId },
      deviationsReportFileName,
      user
    );

    if (reportUrl) {
      triggerFileDownload(reportUrl, deviationsReportFileName);
      dispatch({ type: "download_deviations_tsv", payload: null });
    } else {
      dispatch(apiFailure({ message: "Unable download deviations tsv" }));
    }
  };
};

export default downloadDeviatedTsv;
