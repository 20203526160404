import { AvvirEvent } from "type_aliases";

export type BimtrackProjectSelectedEvent = AvvirEvent<typeof BIMTRACK_PROJECT_SELECTED, number>

const bimtrackProjectSelected = (projectId: number): BimtrackProjectSelectedEvent => ({
  type: BIMTRACK_PROJECT_SELECTED,
  payload: projectId
});

export const BIMTRACK_PROJECT_SELECTED = "bimtrack_project_selected";

export default bimtrackProjectSelected;
