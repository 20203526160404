import React, { FunctionComponent, useMemo } from "react";
import { connect } from "react-redux";
import { Code, DateRangeOutlined, FolderOpenOutlined, Settings, TimelineOutlined } from "@mui/icons-material";
import AccountGroup from "mdi-material-ui/AccountGroup";
import ClipboardOutline from "mdi-material-ui/ClipboardOutline";
import HammerWrench from "mdi-material-ui/HammerWrench";
import Poll from "mdi-material-ui/Poll";
import { useFlags } from "../../services/utilities/launch_darkly_helper";
import { UserRole } from "avvir";

import "../../../css/nav_bar/nav_bar_pages_dropdown.css";
import ExplorerIcon from "../icons/explorer";
import getPageName from "../../services/getters/location_metadata/get_page_name";
import getProjectId from "../../services/getters/project_getters/get_project_id";
import getUserRole from "../../services/getters/user_getters/get_user_role";
import makeToProgress4dReportEvent from "../../actions/nav_bar/make_to_progress_4d_report";
import makeToSuperadminEvent from "../../actions/nav_bar/make_to_superadmin_event";
import makeToViewerEvent from "../../actions/nav_bar/make_to_viewer_event";
import NavBarDropdown from "./nav_bar_dropdown";
import NavBarPageOption from "./nav_bar_page_option";
import PageNames from "../../models/domain/enums/page_names";
import Project from "../../models/domain/project";
import RoutingEvents from "../../services/routing_events";
import toProjectIntegrationsPage from "../../events/routing/to_project_integrations_page";
import toProjectSettingsPage from "../../events/routing/to_project_settings_page";
import toProjectSummaryPage from "../../events/routing/to_project_summary_page";
import toProjectTeamPage from "../../events/routing/to_project_team_page";
import toProjectUploadsPage from "../../events/routing/to_project_uploads_page";
import toWbsWithProject from "../../events/routing/to_wbs_with_project";
import usePopover from "../../services/component_helpers/component_effects/use_popover";
import useProject from "../../queries/projects/use_project";
import useWbsAvailability from "../../queries/projects/use_wbs_availability";

export type Props = ReturnType<typeof mapStateToProps>;

export type PageOption = {
  displayName: string
  icon: React.ReactNode
  pageNames: Set<PageNames>,
  routingEvent: (...args: any[]) => RoutingEvents
  visible: (data: {
    project: Project,
    wbsIsAvailable: boolean,
    recipeV1: boolean,
    userRole: UserRole
  }, flags: {
    customReports: boolean,
    schedulePages: boolean,
    showAllReportsOnSummary: boolean
  }) => boolean
}

export const defaultPageOptions: PageOption[] = [{
  displayName: "Summary",
  icon: <Poll/>,
  pageNames: new Set([PageNames.PROJECT_SUMMARY]),
  routingEvent: toProjectSummaryPage,
  visible: (data, flags) => data.userRole !== UserRole.SUBCONTRACTOR && (data.project?.settings?.projectSummaryPage || flags.customReports),
}, {
  displayName: "Explorer",
  icon: <ExplorerIcon/>,
  pageNames: new Set([PageNames.PROJECT_VIEWER]),
  routingEvent: makeToViewerEvent,
  visible: () => true,
}, {
  displayName: "Reports",
  icon: <TimelineOutlined/>,
  pageNames: new Set([PageNames.PROJECT_REPORTS]),
  routingEvent: makeToProgress4dReportEvent,
  visible: (data, { showAllReportsOnSummary }) => !showAllReportsOnSummary,
}, {
  displayName: "Files",
  icon: <FolderOpenOutlined/>,
  pageNames: new Set([PageNames.PROJECT_UPLOADS]),
  routingEvent: toProjectUploadsPage,
  visible: (data) => data.userRole !== UserRole.SUBCONTRACTOR,
}, {
  displayName: "Schedules",
  icon: <DateRangeOutlined/>,
  pageNames: new Set([]),
  routingEvent: null,
  visible: (data, flags) => flags.schedulePages,
}, {
  displayName: "Budget",
  icon: <ClipboardOutline/>,
  pageNames: new Set([PageNames.BUDGET]),
  routingEvent: toWbsWithProject,
  visible: (data) => data.wbsIsAvailable,
}, {
  displayName: "Team",
  icon: <AccountGroup/>,
  pageNames: new Set([PageNames.PROJECT_TEAM]),
  routingEvent: toProjectTeamPage,
  visible: (data) => data.userRole === UserRole.SUPERADMIN,
}, {
  displayName: "Settings",
  icon: <Settings/>,
  pageNames: new Set([PageNames.PROJECT_SETTINGS]),
  routingEvent: toProjectSettingsPage,
  visible: (data) => data.recipeV1 && data.userRole !== UserRole.SUBCONTRACTOR,
}, {
  displayName: "Integrations",
  icon: <Code/>,
  pageNames: new Set([PageNames.INTEGRATIONS, PageNames.INTEGRATION, PageNames.PHOTO]),
  routingEvent: toProjectIntegrationsPage,
  visible: (data) => data.userRole !== UserRole.SUBCONTRACTOR,
}, {
  displayName: "Operations",
  icon: <HammerWrench/>,
  pageNames: new Set([PageNames.PROJECT, PageNames.PHOTOS]),
  routingEvent: makeToSuperadminEvent,
  visible: (data) => data.userRole === UserRole.SUPERADMIN,
}];

export const NavBarPagesDropdown: FunctionComponent<Props> = (props) => {
  const {
    pageName,
    projectId,
    userRole,
  } = props;
  const [openState, anchorEl, handleOpen, onClose] = usePopover();
  const { schedulePages, recipeV1, customReports, showAllReportsOnSummary } = useFlags();
  const { data: project } = useProject(projectId);
  const { data: wbsIsAvailable } = useWbsAvailability(projectId);

  const pageOptions = useMemo(() => {
    return defaultPageOptions.filter(option => option.visible({
      userRole,
      wbsIsAvailable: project?.settings?.progress5d || wbsIsAvailable,
      project,
      recipeV1
    }, {
      customReports,
      schedulePages,
      showAllReportsOnSummary
    }));
  }, [customReports, project, recipeV1, schedulePages, showAllReportsOnSummary, wbsIsAvailable, userRole]);

  const selectedIndex = useMemo(() => {
    return pageOptions.findIndex((view) => view.pageNames.has(pageName));
  }, [pageName, pageOptions]);

  const pageOptionMenuItems = pageOptions.map((pageOption, index) => {
    return <NavBarPageOption
      pageOption={pageOption}
      onClose={onClose}
      key={`NavBarPagesDropdown-${pageOption.displayName}`}
    />;
  });

  const selectedPage = pageOptions[selectedIndex];

  return <NavBarDropdown
    className="NavBarPagesDropdown-button"
    label={<span className="NavBarPagesDropdown-buttonText">
        {selectedPage?.displayName}
      </span>}
    popoverProps={{
      open: openState,
      anchorEl,
      handleOpen,
      onClose
    }}
    slotProps={{
      button: {
        "aria-label": "Pages Menu Button",
        startIcon: selectedPage?.icon,
      },
      menu: {
        "aria-label": "Pages Menu",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        }
      }
    }}
  >
    {pageOptionMenuItems}
  </NavBarDropdown>;
};

const mapStateToProps = (state, props) => ({
  pageName: getPageName(state, props),
  projectId: getProjectId(state, props),
  userRole: getUserRole(state, props),
});

export default connect(mapStateToProps, null)(NavBarPagesDropdown);
