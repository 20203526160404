import { AvvirEvent } from "type_aliases";

export type ProcoreObservationTypesLoadedEvent = AvvirEvent<typeof PROCORE_OBSERVATION_TYPES_LOADED, any>

const procoreObservationTypesLoaded = (procoreObservationTypes): ProcoreObservationTypesLoadedEvent => ({
  type: PROCORE_OBSERVATION_TYPES_LOADED,
  payload: procoreObservationTypes
});

export const PROCORE_OBSERVATION_TYPES_LOADED = "procore_observation_types_loaded";

export default procoreObservationTypesLoaded;