import React, { FunctionComponent } from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import DotsVertical from "mdi-material-ui/DotsVertical";

import "../../../css/nav_bar/nav_bar_kebab_menu.css";
import NavBarKebabItemLogout from "./kebab_menu_items/nav_bar_kebab_item_logout";
import NavBarKebabItemSendFeedback from "./kebab_menu_items/nav_bar_kebab_item_send_feedback";
import ResourceHubLink from "./kebab_menu_items/resource_hub_link";
import usePopover from "../../services/component_helpers/component_effects/use_popover";
import { connect } from "react-redux";
import getUserEmail from "../../services/getters/user_getters/get_user_email";


export type Props = ReturnType<typeof mapStateToProps> & { children?: React.ReactNode };

export const NavBarKebabMenu: FunctionComponent<Props> = (props) => {
  const {
    children,
    userEmail,
  } = props;

  const [open, anchorEl, handleOpen, onClose] = usePopover();

  return <><IconButton
    className="NavBarKebabMenu-button"
    onClick={handleOpen}
    aria-haspopup="true"
    aria-controls="kebab-menu"
  >
    <DotsVertical/>
  </IconButton>
    <Menu
      className="NavBarKebabMenu"
      id="kebab-menu"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      onClose={onClose}
      onClick={onClose}
    >
      {children}
      <Divider/>
      <ListSubheader disableSticky className="NavBarKebabMenu-label NavBarKebabMenu-userEmail">
        {userEmail}
      </ListSubheader>
      <ResourceHubLink/>
      <NavBarKebabItemSendFeedback/>
      <NavBarKebabItemLogout/>
    </Menu>
  </>;
};

const mapStateToProps = (state, props) => ({
  userEmail: getUserEmail(state, props),
});


export default connect(mapStateToProps)(NavBarKebabMenu);
