import { Reducer } from "redux";

import PlannedBuildingElement from "../../models/domain/planned_building_element";
import { isRoutingEvent } from "../routing_events";
import { AUTODESK_HUBS_LOADED, AutodeskHubsLoadedEvent } from "../../events/loaded/autodesk_hubs_loaded";
import { AUTODESK_PROJECTS_LOADED, AutodeskProjectsLoadedEvent } from "../../events/loaded/autodesk_projects_loaded";
import { AUTODESK_HUB_SELECTED , AutodeskHubSelectedEvent} from "../../events/viewer/autodesk_hub_selected";
import { AUTODESK_PROJECT_SELECTED, AutodeskProjectSelectedEvent } from "../../events/viewer/autodesk_project_selected";
import { AUTODESK_ISSUE_CREATION_DIALOG_SHOWN, AutodeskIssueCreationDialogShownEvent } from "../../events/viewer/autodesk_issue_creation_dialog_shown";
import { AUTODESK_ISSUE_CREATION_DIALOG_DISMISSED, AutodeskIssueCreationDialogDismissedEvent } from "../../events/viewer/autodesk_issue_creation_dialog_dismissed";
import AutodeskHub from "../../models/api/autodesk_hub";
import AutodeskProject from "../../models/api/autodesk_project";
import { Dictionary } from "underscore";


export type AutodeskIssueCreationDialogStore = {
  open: boolean
  selectedElement?: PlannedBuildingElement
  globalId?: string
  hubs?: AutodeskHub[]
  projects?: Dictionary<AutodeskProject[]>
  selectedHubId?: string
  selectedProjectId?: number
  issueTitle?: string
}

type Events =
  | AutodeskIssueCreationDialogShownEvent
  | AutodeskIssueCreationDialogDismissedEvent
  | AutodeskHubsLoadedEvent
  | AutodeskProjectsLoadedEvent
  | AutodeskHubSelectedEvent
  | AutodeskProjectSelectedEvent



const reduceAutodeskIssueCreationDialog: Reducer<AutodeskIssueCreationDialogStore, Events> = (autodeskIssueCreationDialog = { open: false }, event) => {
  if (isRoutingEvent(event)) {
    return { open: false };
  }
  switch (event.type) {
    case AUTODESK_HUBS_LOADED: {
      return {
        ...autodeskIssueCreationDialog,
        hubs: event.payload,
      };
    }
    case AUTODESK_PROJECTS_LOADED: {
      return {
        ...autodeskIssueCreationDialog,
        projects: {
          ...autodeskIssueCreationDialog.projects,
          [event.payload.hubId]: event.payload.projects
        },
      };
    }
    case AUTODESK_HUB_SELECTED: {
      return {
        ...autodeskIssueCreationDialog,
        selectedHubId: event.payload
      };
    }
    case AUTODESK_PROJECT_SELECTED: {
      return {
        ...autodeskIssueCreationDialog,
        selectedProjectId: event.payload
      }
    }
    case AUTODESK_ISSUE_CREATION_DIALOG_SHOWN: {
      return {
        ...autodeskIssueCreationDialog,
        open: true,
        globalId: event.payload.globalId,
        selectedElement: event.payload.selectedElement
      };
    }
    case AUTODESK_ISSUE_CREATION_DIALOG_DISMISSED: {
      return {
        ...autodeskIssueCreationDialog,
        open: false,
      };
    }
    default: {
      return autodeskIssueCreationDialog;
    }
  }
};

export default reduceAutodeskIssueCreationDialog;
