import { AvvirEvent } from "type_aliases";
import { ApiFloorBackup } from "avvir";

export type FloorBackupsLoadedEvent = AvvirEvent<typeof FLOOR_BACKUPS_LOADED, {
  floorId: string,
  backups: ApiFloorBackup[]
}>

export const floorBackupsLoaded = (floorId: string, backups: ApiFloorBackup[]): FloorBackupsLoadedEvent => ({
  type: FLOOR_BACKUPS_LOADED,
  payload: {
    floorId,
    backups
  }
});

export const FLOOR_BACKUPS_LOADED = "floor_backups_loaded";
