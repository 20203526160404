import { Reducer } from "redux";

import { isRoutingEvent } from "../routing_events";
import { Dictionary } from "underscore";
import { BimtrackAssociationLoadedEvent } from "../../events/loaded/bimtrack_association_loaded";
import { BIMTRACK_HUBS_LOADED, BimtrackHubsLoadedEvent } from "../../events/loaded/bimtrack_hubs_loaded";
import { BIMTRACK_PROJECTS_LOADED, BimtrackProjectsLoadedEvent } from "../../events/loaded/bimtrack_projects_loaded";
import { BIMTRACK_ASSOCIATION_DIALOG_SHOWN, BimtrackAssociationDialogShownEvent } from "../../events/viewer/bimtrack_association_dialog_shown";
import { BIMTRACK_ASSOCIATION_DIALOG_DISMISSED, BimtrackAssociationDialogDismissedEvent } from "../../events/viewer/bimtrack_association_dialog_dismissed";
import BimtrackHub from "../../models/api/bimtrack_hub";
import { BIMTRACK_HUB_SELECTED, BimtrackHubSelectedEvent } from "../../events/viewer/bimtrack_hub_selected";
import BimtrackProject from "../../models/api/bimtrack_project";
import { BIMTRACK_PROJECT_SELECTED, BimtrackProjectSelectedEvent } from "../../events/viewer/bimtrack_project_selected";

export type BimtrackAssociationDialogStore = {
  open: boolean
  hubs?: BimtrackHub[]
  projects?: Dictionary<BimtrackProject[]>
  selectedHubId?: string
  selectedProjectId?: number
  issueTitle?: string
  globalId?: string
}

type Events =
    | BimtrackAssociationLoadedEvent
    | BimtrackHubsLoadedEvent
    | BimtrackProjectsLoadedEvent
    | BimtrackAssociationDialogShownEvent
    | BimtrackAssociationDialogDismissedEvent
    | BimtrackHubSelectedEvent
    | BimtrackProjectSelectedEvent

// @ts-ignore
const reduceBimtrackAssociationDialog: Reducer<BimtrackAssociationDialogStore, Events> = (bimtrackAssociationDialog = { open: false }, event) => {
  if (isRoutingEvent(event)) {
    return { open: false };
  }
  switch (event.type) {
    case BIMTRACK_HUBS_LOADED: {
      return {
        ...bimtrackAssociationDialog,
        hubs: event.payload,
      };
    }
    case BIMTRACK_PROJECTS_LOADED: {
      return {
        ...bimtrackAssociationDialog,
        projects: {
          ...bimtrackAssociationDialog.projects,
          [event.payload.hubId]: event.payload.projects
        },
      };
    }
    case BIMTRACK_HUB_SELECTED: {
      return {
        ...bimtrackAssociationDialog,
        selectedHubId: event.payload
      };
    }
    case BIMTRACK_PROJECT_SELECTED: {
      return {
        ...bimtrackAssociationDialog,
        selectedProjectId: event.payload
      }
    }
    case BIMTRACK_ASSOCIATION_DIALOG_SHOWN: {
      return {
        ...bimtrackAssociationDialog,
        open: true,
        issueTitle: event.payload.issueTitle,
        globalId: event.payload.globalId
      };
    }
    case BIMTRACK_ASSOCIATION_DIALOG_DISMISSED: {
      return {
        ...bimtrackAssociationDialog,
        open: false,
      };
    }

    default: {
      return bimtrackAssociationDialog;
    }
  }
};

export default reduceBimtrackAssociationDialog;
