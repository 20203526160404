import { AvvirEvent } from "type_aliases";

export type PushToProcoreDialogShownEvent = AvvirEvent<typeof PUSH_TO_PROCORE_DIALOG_SHOWN, { procoreAccessToken: any, pdfType: any, selectedElement:any }>

const pushToProcoreDialogShown = (procoreAccessToken, pdfType , selectedElement): PushToProcoreDialogShownEvent => ({
  type: PUSH_TO_PROCORE_DIALOG_SHOWN,
  payload: {
    procoreAccessToken,
    pdfType,
    selectedElement
  }
});

export const PUSH_TO_PROCORE_DIALOG_SHOWN = "push_to_procore_dialog_shown";

export default pushToProcoreDialogShown;
