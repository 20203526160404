import NotificationLevel from "./enums/notification_level";
import PanelType from "./enums/panel_type";

export default class ToastNotification {
  constructor(level, message, link?, manualClose?, panel?) {
    this.level = level;
    this.message = message;
    this.link = link;
    this.expiry = this.expiryForMessage(message);
    this.manualClose = manualClose || false;
    this.panel = panel || null;
  }
  level: NotificationLevel;
  message: string;
  link: string;
  expiry: Date
  manualClose: boolean;
  panel: PanelType

  expiryForMessage = (message: string): Date => {
    const animationTime = 500;
    let readingTime = 1000
    if (message) {
      const wordCount = message.split(" ").length;
      readingTime += 300 * wordCount;
    }
    readingTime = Math.min(readingTime, 10000); // Maximum 10 seconds

    return new Date((new Date()).getTime() + readingTime + animationTime);
  };
}
