import { parse } from "csv-parse";
import { stringify } from "csv-stringify";
import { ApiScanDataset } from "avvir";

import DateConverter from "./date_converter";
import ScanDataset from "../../models/domain/scan_dataset";
import withPromise from "../utilities/general/with_promise";


const dateFormatter = DateConverter.getDateFormatter("MM/DD/YYYY");

export default class ScanDatasetConverter {
  static fromApi(apiScanDataset: ApiScanDataset): ScanDataset {
    return new ScanDataset({
      id: apiScanDataset.id,
      firebaseId: apiScanDataset.firebaseId,
      firebaseFloorId: apiScanDataset.firebaseFloorId,
      scanNumber: apiScanDataset.scanNumber,
      scanDate: apiScanDataset.scanDate,
      notes: apiScanDataset.notes,
      name: apiScanDataset.name,
      manualQcPresent: null,
      scanAnalysisPresent: apiScanDataset.dataPresences?.scanAnalysis,
      analysisCompleted: apiScanDataset.analysisCompleted,
      coarseAlignmentMatrix: apiScanDataset.coarseAlignmentMatrix,
      fineAlignmentMatrix: apiScanDataset.fineAlignmentMatrix,
      qaStarted: DateConverter.instantToDate(apiScanDataset.qaStarted),
      qaComplete: DateConverter.instantToDate(apiScanDataset.qaComplete),
      qaState: apiScanDataset.qaState,
      hasScanFile: apiScanDataset.hasScanFile
    });
  };

  static toApi(scanDataset: ScanDataset): ApiScanDataset {
    return new ApiScanDataset({
      id: scanDataset.id,
      firebaseId: scanDataset.firebaseId,
      firebaseFloorId: scanDataset.firebaseFloorId,
      scanNumber: scanDataset.scanNumber,
      coarseAlignmentMatrix: scanDataset.coarseAlignmentMatrix,
      fineAlignmentMatrix: scanDataset.fineAlignmentMatrix,
      notes: scanDataset.notes,
      name: scanDataset.name,
      scanDate: scanDataset.scanDate,
      analysisCompleted: scanDataset.analysisCompleted,
      dataPresences: { scanAnalysis: scanDataset.scanAnalysisPresent },
      qaStarted: scanDataset.qaStarted,
      qaComplete: scanDataset.qaComplete,
      qaState: scanDataset.qaState,
      hasScanFile: scanDataset.hasScanFile
    });
  };

  static fromTsv(tsvContent: string, firebaseFloorId: string): Promise<ApiScanDataset[]> {
    const [readSuccess, readError, readingContent] = withPromise<ApiScanDataset[]>();

    parse(tsvContent, {
      delimiter: "\t",
      trim: true,
      fromLine: 2,
      columns: ["scanDate", "name"],
      onRecord: (record, context) => {
        if (context.header) {
          return record;
        } else {
          return new ApiScanDataset({
            scanDate: dateFormatter.parseUTC(record.scanDate),
            name: record.name,
            firebaseFloorId,
          });
        }
      }
    }, (err, results) => {
      if (err) {
        readError(err);
      } else {
        readSuccess(results);
      }
    });

    return readingContent;
  }

  static toTsv(scanDatasets: {scanDate: Date, name?: string}[]): Promise<string> {
    const [writeSuccess, writeError, writingContent] = withPromise<string>();
    stringify(scanDatasets, {
      delimiter: "\t",
      columns: ["scanDate", "name"],
      cast: {
        date: dateFormatter.formatUTC.bind(dateFormatter)
      },
      header: true
    }, (err, results) => {
      if (err) {
        writeError(err);
      } else {
        writeSuccess(results);
      }
    });

    return writingContent;
  }
}
