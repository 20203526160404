import Avvir, { ApiGroup } from "avvir";

import getFloorId from "../../services/getters/floor_getters/get_floor_id";
import getProjectId from "../../services/getters/project_getters/get_project_id";
import getUser from "../../services/getters/base_getters/get_user";
import groupCreated, { GroupCreatedEvent } from "../../events/groups/group_created";
import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";

import type { ApiFailureEvent } from "../../events/notifications/failures/api_failure";

const createPlannedBuildingElementGroup = (globalIds: readonly string[]): AvvirThunk<Promise<ApiGroup>, ApiFailureEvent | GroupCreatedEvent> => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = getProjectId(state, {});
    const user = getUser(state, {});
    const floorId = getFloorId(state, {});
    return Avvir.api.groups.createElementGroup({ projectId, floorId }, globalIds as string[], user)
      .then((group) => {
        dispatch(groupCreated(group.id, group));
        return group;
      });
  };
};

export default makeEventfulAction<Promise<ApiGroup>, ApiFailureEvent | GroupCreatedEvent, typeof createPlannedBuildingElementGroup>(
  "createPlannedBuildingElementsGroup",
  createPlannedBuildingElementGroup);
