import type { AvvirEvent } from "type_aliases";

export type PhotoSessionsDeletedEvent = AvvirEvent<typeof PHOTO_SESSIONS_DELETED, Set<number>>

const photoSessionsDeleted = (photoSessionIds: Set<number>): PhotoSessionsDeletedEvent => ({
  type: PHOTO_SESSIONS_DELETED,
  payload: photoSessionIds
});

export const PHOTO_SESSIONS_DELETED = "photo_sessions_deleted";

export default photoSessionsDeleted;
