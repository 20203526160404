import { AvvirEvent } from "type_aliases";
import AutodeskHub from "../../models/api/autodesk_hub";

export type AutodeskHubsLoadedEvent = AvvirEvent<typeof AUTODESK_HUBS_LOADED, AutodeskHub[]>

const autodeskHubsLoaded = (hubs: AutodeskHub[]): AutodeskHubsLoadedEvent => ({
  type: AUTODESK_HUBS_LOADED,
  payload: hubs
});

export const AUTODESK_HUBS_LOADED = "autodesk_hubs_loaded";

export default autodeskHubsLoaded;