import type { ApiCloudFile } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type FileAssociationsPayload = {
  projectId: string,
  files: ApiCloudFile[],
  previousFiles: ApiCloudFile[]
}

export type FileAssociationsUpdatedEvent = AvvirEvent<typeof FILE_ASSOCIATIONS_UPDATED, FileAssociationsPayload>

const fileAssociationsUpdated = (projectId: string, previousFiles: ApiCloudFile[], files: ApiCloudFile[]): FileAssociationsUpdatedEvent => ({
  type: FILE_ASSOCIATIONS_UPDATED,
  payload: {
    projectId,
    previousFiles,
    files
  }
});

export const FILE_ASSOCIATIONS_UPDATED = "file_associations_updated";

export default fileAssociationsUpdated;
