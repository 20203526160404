import type ProjectReportVersion from "../../../models/domain/project_report_version";
import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ProjectReportVersionAddedEvent = AvvirEvent<typeof PROJECT_REPORT_VERSION_ADDED, { projectId: string, projectReportId: number, reportVersion: ProjectReportVersion }>

const projectReportVersionAdded = ({ projectId, projectReportId }: AssociationIds & { projectReportId: number }, reportVersion: ProjectReportVersion): ProjectReportVersionAddedEvent => ({
  type: PROJECT_REPORT_VERSION_ADDED,
  payload: {
    projectId,
    projectReportId,
    reportVersion
  }
});

export const PROJECT_REPORT_VERSION_ADDED = "project_report_version_added";

export default projectReportVersionAdded;
