import Avvir from "avvir";

import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";
import scanDatasetUpdated, { ScanDatasetUpdatedEvent } from "../../events/superadmin/scan_datasets/scan_dataset_updated";

import type { ApiFailureEvent } from "../../events/notifications/failures/api_failure";
import type { ApiScanDatasetQaState, AssociationIds } from "avvir";

const updateScanDatasetQaState = ({ projectId, floorId, scanDatasetId }: AssociationIds, newQaState: ApiScanDatasetQaState): AvvirThunk<Promise<void>, ScanDatasetUpdatedEvent | ApiFailureEvent> => {
  return (dispatch, getState) => {
    return Avvir.api.scanDatasets.updateQaState({ projectId, floorId, scanDatasetId }, newQaState, getState().user).then((apiScanDataset) => {
      dispatch(scanDatasetUpdated(apiScanDataset));
    });
  };
};

export default makeEventfulAction<Promise<void>, ScanDatasetUpdatedEvent | ApiFailureEvent, typeof updateScanDatasetQaState>("updateScanDatasetQaState", updateScanDatasetQaState);
