import type { ApiProjectArea } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ProjectAreaUpdatedEvent = AvvirEvent<typeof PROJECT_AREA_UPDATED, { projectId: string, projectArea: ApiProjectArea }>

const projectAreaUpdated = (projectId: string, projectArea: ApiProjectArea): ProjectAreaUpdatedEvent => ({
  type: PROJECT_AREA_UPDATED,
  payload: {
    projectId,
    projectArea
  }
});

export const PROJECT_AREA_UPDATED = "project_area_updated";

export default projectAreaUpdated;
