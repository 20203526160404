import queryString from "query-string";

export default class ProcoreApi {
  static baseUrl = process.env.REACT_APP_PROCORE_API_DOMAIN;
  static clientId = process.env.REACT_APP_PROCORE_CLIENT_ID;
  static oauthUrl = `${ProcoreApi.baseUrl}/oauth/authorize`;

  static getLoginUrl({ pdfType }) {
    const redirectUri = `${window.location.origin}/procore?pdfType=${pdfType}`;
    const params = queryString.stringify({
      response_type: "token",
      client_id: ProcoreApi.clientId,
      redirect_uri: redirectUri
    });

    return (ProcoreApi.oauthUrl + "?" + params);
  };
}
