import type { ApiProjectCostAnalysisProgress } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type MasterformatSelectionUpdatedEvent = AvvirEvent<typeof MASTERFORMAT_SELECTION_UPDATED, { projectId: string, projectCostAnalysis: ApiProjectCostAnalysisProgress[] }>

const masterformatSelectionUpdated = (projectId: string, projectCostAnalysis: ApiProjectCostAnalysisProgress[]) => ({
  type: MASTERFORMAT_SELECTION_UPDATED,
  payload: {
    projectId,
    projectCostAnalysis
  }
});

export const MASTERFORMAT_SELECTION_UPDATED = "masterformat_selection_updated";
export default masterformatSelectionUpdated;
