import { AvvirEvent } from "type_aliases";

export type AutodeskProjectsLoadedEvent = AvvirEvent<typeof AUTODESK_PROJECTS_LOADED, { hubId: any, projects: any[] }>

const autodeskProjectsLoaded = (hubId: any, projects: any[]): AutodeskProjectsLoadedEvent => ({
  type: AUTODESK_PROJECTS_LOADED,
  payload: { hubId, projects }
});

export const AUTODESK_PROJECTS_LOADED = "autodesk_projects_loaded";

export default autodeskProjectsLoaded;