import { Dispatch, Middleware } from "redux";
import _ from "underscore";

import UndoableAction from "../models/domain/undoable_action";
import undoableActionOccurred from "../events/undo/undoable_action_occurred";
import undoableActionRedone from "../events/undo/undoable_action_redone";
import undoableActionUndone from "../events/undo/undoable_action_undone";
import { ActionHistoryEntry } from "./reducers/reduce_action_history";
import { NestedKeyOf, UndoableReduxStore } from "type_aliases";
import { REDO_REQUESTED } from "../events/undo/redo_requested";
import { ReduxStore } from "./reducers/root_reducer";
import { UNDO_REQUESTED } from "../events/undo/undo_requested";
import apiFailure from "../events/notifications/failures/api_failure";

function getSliceFromStore(
  store: UndoableReduxStore,
  keys: NestedKeyOf<UndoableReduxStore>
) {
  // @ts-ignore
  return keys.reduce(
    (acc, key) => acc[key],
    store
  );
}

const undoMiddleware: Middleware<object, ReduxStore> = (store) => (next: Dispatch) => (event) => {
  if (event instanceof UndoableAction) {
    const actionHistoryEntry: ActionHistoryEntry = {
      action: event,
      previousState: getSliceFromStore(store.getState(), event.location),
      nextState: null
    };
    event.perform(store.dispatch, store.getState).then(() => {
      actionHistoryEntry.nextState = getSliceFromStore(store.getState(), event.location);
      store.dispatch(undoableActionOccurred(actionHistoryEntry));
    });
  } else {
    if (event.type === UNDO_REQUESTED) {
      const undoableAction = _.last(store.getState().actionHistory.pastEdits);
      if (undoableAction) {
        store.dispatch(undoableActionUndone(undoableAction));
        undoableAction.action.undo(store.dispatch, store.getState)
          .catch((error) => {
            store.dispatch(apiFailure(error, "Unable to undo action"));
          });
      }
    } else if (event.type === REDO_REQUESTED) {
      const undoableAction = store.getState().actionHistory.futureEdits[0];
      // TODO: This makes it look like we redid the action, but it doesn't actually make the api calls to redo it
      if (undoableAction) {
        store.dispatch(undoableActionRedone(undoableAction));
      }
    } else {
      next(event);
    }
  }
};

export default undoMiddleware;
