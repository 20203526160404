import React, { FunctionComponent } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useFlags } from "../../../services/utilities/launch_darkly_helper";
import { youTrackUrl } from "../../../services/youtrack";

export type Props = Record<string, never>;

export const openFeedbackPopup = () => {
  window.open(
    youTrackUrl,
    "_blank",
    "popup,innerWidth=512,innerHeight=512,left=100,top=100");
}

export const NavBarKebabItemSendFeedback: FunctionComponent<Props> = (props) => {
  const { sendFeedback } = useFlags();

  if (!sendFeedback) {
    return null;
  }

  return <MenuItem className="NavBarKebabItemSendFeedback NavBarKebabMenu-menuItem" onClick={openFeedbackPopup}>
    Report Issue
  </MenuItem>;
};

export default React.memo(NavBarKebabItemSendFeedback);
