import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ExportBatchIfcProcessCheckedEvent = AvvirEvent<typeof EXPORT_BATCH_IFC_PROCESS_CHECKED, {
  processName: string
  ifcType: string
  status: "running" | "complete"
  projectId: string
  floorScanDatasetIds: string | string[]
  startTime: Date
}>

const exportBatchIfcProcessChecked = ({ projectId, floorScanDatasetIds }: AssociationIds & { floorScanDatasetIds: string | string[] }, ifcType: string, processName: string, status: "running" | "complete", startTime: Date): ExportBatchIfcProcessCheckedEvent => ({
  type: EXPORT_BATCH_IFC_PROCESS_CHECKED,
  payload: {
    processName,
    ifcType,
    status,
    projectId,
    floorScanDatasetIds,
    startTime
  }
});

export const EXPORT_BATCH_IFC_PROCESS_CHECKED = "export_batch_ifc_process_checked";

export default exportBatchIfcProcessChecked;
