import type { AssociationIds } from "avvir";
import Avvir, { ApiCloudFile } from "avvir";
import _ from "underscore";

import apiFailure, { ApiFailureEvent } from "../../events/notifications/failures/api_failure";
import DateConverter from "../../services/converters/date_converter";
import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";
import triggerFileDownload from "../../services/utilities/general/trigger_file_download";
import type { ReduxStore } from "../../services/reducers/root_reducer";

const dateFormatter = DateConverter.getDateFormatter("MMM-DD-YYYY");

const filenameSafeString = (str: string) => {
  return str.replace(/[^a-zA-Z0-9]/gi, "-").replace(/-+/gi, "-");
}
const getFileName = (action: string, state: ReduxStore, associationIds: AssociationIds, type?: string) => {
  const project = state.domain.projects.byFirebaseId[associationIds.projectId];
  const floor = state.domain.floors.byFirebaseId[associationIds.floorId];
  const scanDataset = state.domain.scanDatasets.byFirebaseId[associationIds.scanDatasetId];

  let projectName = filenameSafeString(project?.name||"project");
  let floorName = floor ? filenameSafeString(floor?.floorNumber||"floor") : "";
  let scanDatasetName = scanDataset ? filenameSafeString((scanDataset?.name || dateFormatter.formatUTC(scanDataset.scanDate)).toString()) : "";
  if(action === "all_deviations") {
    scanDatasetName = "ALL-SCANS"
  }

  if(type && type === "batch_download") {
    return `${projectName}_${filenameSafeString(action)}_batch_ifc.zip`;
  } else {
    return `${projectName}_${floorName}_${scanDatasetName}_${filenameSafeString(action)}.ifc`;
  }
}
const downloadExportedIfc = (associationIds: AssociationIds, actionForEventTracking: string, type?: string): AvvirThunk<void, ApiFailureEvent> => {
  return (dispatch, getState) => {
    let eventType, scanDatasetFilePurposeType;
    let user = getState().user;

    if (type && type === "batch_download") {
      scanDatasetFilePurposeType = "IFC_BATCH_ZIP";
      eventType = "batch_download_ifc";
    }
    else if (actionForEventTracking === "as_built") {
      scanDatasetFilePurposeType = "INCLUDED_BIM_IFC";
      eventType = "download_exported_ifc";
    } else if (actionForEventTracking === "deviations" || actionForEventTracking === "all_deviations" || actionForEventTracking === "current_deviations") {
      scanDatasetFilePurposeType = "DEVIATIONS_BIM_IFC";
      eventType = "download_deviations_ifc";
    } else if (actionForEventTracking === "not_built") {
      scanDatasetFilePurposeType = "NOT_BUILT_IFC";
      eventType = "download_not_built_ifc";
    } else {
      dispatch(apiFailure({ message: "Unable to export IFC" }));
    }
    const fileName = getFileName(actionForEventTracking, getState(), associationIds, type);

    return Avvir.api.files.getScanDatasetFiles(associationIds, scanDatasetFilePurposeType, user).then((cloudFiles) => {
      const cloudFile = _.reduce(cloudFiles.files, (maxSoFar, currentCloudFile) => {
        if (maxSoFar.createdAt > currentCloudFile.createdAt) {
          return maxSoFar;
        } else {
          return currentCloudFile;
        }
      }, new ApiCloudFile({}));
      if (cloudFile) {
        dispatch({ type: eventType, payload: cloudFile.url });
        triggerFileDownload(cloudFile.url, fileName);
      } else {
        dispatch(apiFailure({ message: "Unable to export IFC" }));
      }
    });
  };
};

export default makeEventfulAction("downloadExportedIfc", downloadExportedIfc);
