import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ExportBatchIfcTriggeredEvent = AvvirEvent<typeof EXPORT_BATCH_IFC_TRIGGERED, {
  processName: string
  projectId: string
  floorScanDatasetIds: string | string[]
  ifcType: string
  startTime: Date
}>

const exportBatchIfcTriggered = ({ projectId, floorScanDatasetIds }: AssociationIds & { floorScanDatasetIds: string | string[] }, ifcType: string, processName: string, startTime: Date): ExportBatchIfcTriggeredEvent => ({
  type: EXPORT_BATCH_IFC_TRIGGERED,
  payload: {
    processName,
    projectId,
    floorScanDatasetIds,
    ifcType,
    startTime
  }
});

export const EXPORT_BATCH_IFC_TRIGGERED = "export_batch_ifc_triggered";

export default exportBatchIfcTriggered;
