import type { Action } from "redux";

export type WbsUpdateFailedEvent = Action<typeof WBS_UPDATE_FAILED>

const wbsUpdateFailed = (): WbsUpdateFailedEvent => ({
  type: WBS_UPDATE_FAILED,
});

export const WBS_UPDATE_FAILED = "wbs_update_failed";

export default wbsUpdateFailed;
