import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ExportIfcTriggeredEvent = AvvirEvent<typeof EXPORT_IFC_TRIGGERED, {
  processName: string
  projectId: string
  floorId: string
  scanDatasetId: string
  ifcType: string
  startTime: Date
}>

const exportIfcTriggered = ({ projectId, floorId, scanDatasetId }: AssociationIds, ifcType: string, processName: string, startTime: Date): ExportIfcTriggeredEvent => ({
  type: EXPORT_IFC_TRIGGERED,
  payload: {
    processName,
    projectId,
    floorId,
    scanDatasetId,
    ifcType,
    startTime
  }
});

export const EXPORT_IFC_TRIGGERED = "export_ifc_triggered";

export default exportIfcTriggered;
