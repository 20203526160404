import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type DeviationMarkedAsResolvedEvent = AvvirEvent<typeof DEVIATION_MARKED_AS_RESOLVED, { floorId: string, scanDatasetId: string, deviationGlobalId: string, isFixedDeviation: boolean }>

const deviationMarkedAsResolved = ({ floorId, scanDatasetId, globalId }: AssociationIds, isFixedDeviation: boolean): DeviationMarkedAsResolvedEvent => ({
  type: DEVIATION_MARKED_AS_RESOLVED,
  payload: {
    floorId,
    scanDatasetId,
    deviationGlobalId: globalId,
    isFixedDeviation
  }
});

export const DEVIATION_MARKED_AS_RESOLVED = "deviation_marked_as_resolved";

export default deviationMarkedAsResolved;

