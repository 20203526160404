import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type DeviationMarkedAsNotResolvedEvent = AvvirEvent<typeof DEVIATION_MARKED_AS_NOT_RESOLVED, {
  floorId: string,
  scanDatasetId: string,
  deviationGlobalId: string,
  isFixedDeviation: boolean
}>

const deviationMarkedAsNotResolved = ({ floorId, scanDatasetId, globalId }: AssociationIds, isFixedDeviation: boolean): DeviationMarkedAsNotResolvedEvent => ({
  type: DEVIATION_MARKED_AS_NOT_RESOLVED,
  payload: {
    floorId,
    scanDatasetId,
    deviationGlobalId: globalId,
    isFixedDeviation
  }
});

export const DEVIATION_MARKED_AS_NOT_RESOLVED = "deviation_marked_as_not_resolved";

export default deviationMarkedAsNotResolved;
