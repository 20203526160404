import { Reducer } from "redux";
import { produce } from "immer";
import _ from "underscore";

import ApiTrade from "../../../models/api/api_trade";
import { TRADES_LOADED, TradesLoadedEvent } from "../../../events/loaded/trade/trades_loaded";
import { TRADES_UPDATED, TradesUpdatedEvent } from "../../../events/project_settings/trades_updated";

type TradesEvents =
  | TradesLoadedEvent
  | TradesUpdatedEvent;

export type TradesStore = {
  byFirebaseProjectId: { [projectId: string]: { byCode: { [code: string]: ApiTrade } } }
}

const reduceTrades: Reducer<TradesStore, TradesEvents> = (trades: TradesStore = { byFirebaseProjectId: {} }, event) => {
  return produce<TradesStore>(trades, (tradesDraft) => {
    if (!tradesDraft.byFirebaseProjectId) {
      tradesDraft.byFirebaseProjectId = {};
    }
    switch (event.type) {
      case TRADES_LOADED: {
        if (!tradesDraft.byFirebaseProjectId[event.payload.projectId]) {
          tradesDraft.byFirebaseProjectId[event.payload.projectId] = { byCode: {} };
        }

        tradesDraft.byFirebaseProjectId[event.payload.projectId].byCode = _.chain(event.payload.trades)
          .sortBy("code")
          .map(trade => new ApiTrade(trade))
          .indexBy("code")
          .value();
        return tradesDraft;
      }
      case TRADES_UPDATED: {
        event.payload.trades.forEach(trade => {
          const existingTrade = tradesDraft.byFirebaseProjectId[event.payload.projectId].byCode[trade.code];
          if (existingTrade) {
            _(trade).forEach((value, key) => {
              const existingValue = existingTrade?.[key];
              if (existingValue !== value) {
                if (Array.isArray(existingValue)) {
                  if (_.difference(existingValue, value).length) {
                    existingTrade[key] = value;
                  }
                } else {
                  existingTrade[key] = value;
                }
              }
            });
          } else {
            tradesDraft.byFirebaseProjectId[event.payload.projectId].byCode[trade.code] = new ApiTrade(trade);
          }
        });
        return tradesDraft;
      }
      default: {
        return tradesDraft;
      }
    }
  });
};

export default reduceTrades;

