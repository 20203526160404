import _ from "underscore";
import { Action, Reducer } from "redux";
import { ApiRunningProcess, RunningProcessStatus } from "avvir";

import makeUpdateModel from "./domain/make_update_model";
import Pipeline from "../../models/domain/pipeline";
import { addPipelineToStorage, removePipelineFromStorage } from "../utilities/store_in_local_storage";
import { ByDatabaseId } from "type_aliases";
import { EXPORT_IFC_PROCESS_CHECKED, ExportIfcProcessCheckedEvent } from "../../events/export_ifc_process_checked";
import { EXPORT_BATCH_IFC_PROCESS_CHECKED, ExportBatchIfcProcessCheckedEvent } from "../../events/export_batch_ifc_process_checked";
import { EXPORT_IFC_TRIGGERED, ExportIfcTriggeredEvent } from "../../events/export_ifc_triggered";
import { EXPORT_BATCH_IFC_TRIGGERED, ExportBatchIfcTriggeredEvent } from "../../events/export_batch_ifc_triggered";
import { FILE_UPLOAD_PROGRESSED, FileUploadProgressedEvent } from "../../events/files/upload/file_upload_progressed";
import { PIPELINE_RUNNING_PROCESS_CHECKED, PipelineRunningProcessCheckedEvent } from "../../events/pipeline_running_process_checked";
import { PIPELINE_RUNNING_PROCESS_TRIGGERED, PipelineRunningProcessTriggeredEvent } from "../../events/pipeline_running_process_triggered";
import { RUNNING_PROCESS_CHECKED, RunningProcessCheckedEvent } from "../../events/running_process/running_process_checked";
import { RUNNING_PROCESS_FAILED, RunningProcessFailedEvent } from "../../events/running_process/running_process_failed";
import { RUNNING_PROCESS_SUCCEEDED, RunningProcessSucceededEvent } from "../../events/running_process/running_process_succeeded";
import { UPDATE_PROJECT_MASTERFORMAT_PROGRESS_COMPLETED, UpdateProjectMasterformatProgressCompletedEvent } from "../../events/update_project_masterformat_progress_completed";
import { UPDATE_PROJECT_MASTERFORMAT_PROGRESS_STARTED, UpdateProjectMasterformatProgressStartedEvent } from "../../events/update_project_masterformat_progress_started";
import { UPDATE_FLOOR_MASTERFORMAT_PROGRESS_COMPLETED, UpdateFloorMasterformatProgressCompletedEvent } from "../../events/update_floor_masterformat_progress_completed";
import { UPDATE_FLOOR_MASTERFORMAT_PROGRESS_STARTED, UpdateFloorMasterformatProgressStartedEvent } from "../../events/update_floor_masterformat_progress_started";
import { Process } from "../../models/domain/running_process";

type RunningProcessesEvents =
  | ExportIfcTriggeredEvent
  | ExportBatchIfcTriggeredEvent
  | ExportIfcProcessCheckedEvent
  | ExportBatchIfcProcessCheckedEvent
  | PipelineRunningProcessTriggeredEvent
  | PipelineRunningProcessCheckedEvent
  | FileUploadProgressedEvent
  | UpdateProjectMasterformatProgressStartedEvent
  | UpdateProjectMasterformatProgressCompletedEvent
  | UpdateFloorMasterformatProgressStartedEvent
  | UpdateFloorMasterformatProgressCompletedEvent
  | RunningProcessCheckedEvent
  | RunningProcessFailedEvent
  | RunningProcessSucceededEvent
  | Action<"update_selected_elements_status_started">
  | Action<"update_selected_elements_status_done">
  | Action<"update_selected_elements_status_failed">

type FileUploadStatus = { fileTitle: string, isUploading: boolean, progress: number };

export interface RunningProcessesStore {
  uploads?: { [fileName: string]: FileUploadStatus };
  byId: ByDatabaseId<Pipeline>;
  byRunningProcessId: ByDatabaseId<ApiRunningProcess>;
  exports: Process[];
  isSelectedElementsUpdating: boolean;
}

const beforeUnloadEventHandler = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = "Do you want to leave this site? Changes you made may not be saved.";
};

const addFileUploadStatus = (runningProcesses: RunningProcessesStore, fileUploadStatus: FileUploadStatus) => ({
  ...runningProcesses,
  uploads: {
    ...runningProcesses.uploads,
    [fileUploadStatus.fileTitle]: fileUploadStatus
  }
});

const updateOrRemoveProcess = function updateOrRemoveProcess(runningProcesses: RunningProcessesStore, processIndex: number, processStatus: "running" | "complete") {
  if (processIndex === -1) {
    return runningProcesses;
  } else {
    if (processStatus === "running") {
      runningProcesses.exports[processIndex] = {
        ...runningProcesses.exports[processIndex],
        updates: runningProcesses.exports[processIndex].updates + 1
      };

      return {
        ...runningProcesses,
        exports: [
          ...runningProcesses.exports,
        ]
      };
    } else {
      runningProcesses.exports.splice(processIndex, 1);
      return {
        ...runningProcesses,
        exports: [
          ...(runningProcesses.exports || [])
        ]
      };
    }
  }
};

type ProcessEvent = PipelineRunningProcessCheckedEvent
                    | ExportIfcProcessCheckedEvent
                    | UpdateProjectMasterformatProgressCompletedEvent
                    | UpdateFloorMasterformatProgressCompletedEvent
                    | ExportBatchIfcProcessCheckedEvent;

const checkProcess = (runningProcesses: RunningProcessesStore, event: ProcessEvent): RunningProcessesStore => {
  switch (event.type) {
    case EXPORT_IFC_PROCESS_CHECKED: {
      const payload = event.payload;
      const checkedIndex = _(runningProcesses.exports).findIndex({ processName: payload.processName });
      return updateOrRemoveProcess(runningProcesses, checkedIndex, event.payload.status);
    }
    case EXPORT_BATCH_IFC_PROCESS_CHECKED: {
      const payload = event.payload;
      const checkedIndex = _(runningProcesses.exports).findIndex({ processName: payload.processName });
      return updateOrRemoveProcess(runningProcesses, checkedIndex, event.payload.status);
    }
    case UPDATE_PROJECT_MASTERFORMAT_PROGRESS_COMPLETED: {
      const payload = event.payload;
      const checkedIndex = _(runningProcesses.exports).findIndex({ processId: payload.processId });
      return updateOrRemoveProcess(runningProcesses, checkedIndex, "complete");
    }
    case PIPELINE_RUNNING_PROCESS_CHECKED: {
      if (event.payload.status === RunningProcessStatus.FAILED
          || event.payload.status === RunningProcessStatus.COMPLETED
          || !event.payload.id) {
        removePipelineFromStorage(event.payload.id);
      } else {
        addPipelineToStorage(event.payload);
      }

      if (event.payload.id) {
        return updateRunningProcess(runningProcesses, event.payload.id, (process) => {
          const pipeline = new Pipeline(event.payload);
          pipeline.updates = process.updates + 1;
          return { ...pipeline };
        });
      }
      return runningProcesses;
    }
  }
};

const updateRunningProcess = makeUpdateModel(new Pipeline());

const defaultRunningProcesses: RunningProcessesStore = {
  exports: [],
  byId: {},
  byRunningProcessId: {},
  isSelectedElementsUpdating: false,
};

type RunningProcessesReducer = Reducer<RunningProcessesStore, RunningProcessesEvents>

// TODO: Make behavior consistent. Right now, pipeline running processes get removed from the list after failure, but gateway processes don't
const reduceRunningProcesses: RunningProcessesReducer = (runningProcesses = defaultRunningProcesses, event) => {
  switch (event?.type) {
    case "update_selected_elements_status_started": {
      return {
        ...runningProcesses,
        isSelectedElementsUpdating: true,
      };
    }
    case "update_selected_elements_status_failed":
    case "update_selected_elements_status_done": {
      return {
        ...runningProcesses,
        isSelectedElementsUpdating: false,
      };
    }
    case EXPORT_IFC_TRIGGERED: {
      return {
        ...runningProcesses,
        exports: [
          ...(runningProcesses.exports || []),
          {
            processName: event.payload.processName,
            projectId: event.payload.projectId,
            floorId: event.payload.floorId,
            scanDatasetId: event.payload.scanDatasetId,
            startTime: event.payload.startTime,
            ifcType: event.payload.ifcType,
            title: "Ifc Export Processing...",
            updates: 0
          }
        ]
      };
    }
    case EXPORT_BATCH_IFC_TRIGGERED: {
      return {
        ...runningProcesses,
        exports: [
          ...(runningProcesses.exports || []),
          {
            processName: event.payload.processName,
            projectId: event.payload.projectId,
            floorScanDatasetIds: event.payload.floorScanDatasetIds,
            startTime: event.payload.startTime,
            ifcType: event.payload.ifcType,
            title: "Batch Ifc Export Processing...",
            updates: 0
          }
        ]
      };
    }
    case EXPORT_IFC_PROCESS_CHECKED: {
      return checkProcess(runningProcesses, event);
    }
    case RUNNING_PROCESS_FAILED:
    case RUNNING_PROCESS_SUCCEEDED:
    case RUNNING_PROCESS_CHECKED: {
      return {
        ...runningProcesses,
        byRunningProcessId: {
          ...runningProcesses.byRunningProcessId,
          [event.payload.runningProcess.id]: {
            ...new ApiRunningProcess(event.payload.runningProcess)
          }
        }
      };
    }
    case PIPELINE_RUNNING_PROCESS_TRIGGERED: {
      if (event.payload.status === RunningProcessStatus.RUNNING && event.payload.id) {
        addPipelineToStorage(event.payload);
        return {
          ...runningProcesses,
          byId: {
            ...runningProcesses.byId,
            [event.payload.id]: {
              ...new Pipeline(event.payload),
            }
          }
        };
      }
      return runningProcesses;
    }
    case UPDATE_PROJECT_MASTERFORMAT_PROGRESS_STARTED: {
      return {
        ...runningProcesses,
        exports: [
          ...(runningProcesses.exports || []),
          {
            processId: event.payload.processId,
            projectId: event.payload.projectId,
            startTime: event.payload.startTime,
            title: "Updating 4D Progress...",
            updates: 0
          }
        ]
      };
    }
    case UPDATE_PROJECT_MASTERFORMAT_PROGRESS_COMPLETED: {
      return checkProcess(runningProcesses, event);
    }

    case UPDATE_FLOOR_MASTERFORMAT_PROGRESS_STARTED: {
      return {
        ...runningProcesses,
        exports: [
          ...(runningProcesses.exports || []),
          {
            processId: event.payload.processId,
            projectId: event.payload.projectId,
            floorId: event.payload.floorId,
            startTime: event.payload.startTime,
            title: "Updating Floor 4D Progress...",
            updates: 0
          }
        ]
      };
    }
    case UPDATE_FLOOR_MASTERFORMAT_PROGRESS_COMPLETED: {
      return checkProcess(runningProcesses, event);
    }

    case PIPELINE_RUNNING_PROCESS_CHECKED: {
      return checkProcess(runningProcesses, event);
    }
    case FILE_UPLOAD_PROGRESSED: {
      const newRunningProcesses = addFileUploadStatus(runningProcesses, event.payload);
      for (const upload of Object.values(newRunningProcesses.uploads)) {
        if (upload.isUploading) {
          window.addEventListener("beforeunload", beforeUnloadEventHandler);
          break;
        } else {
          window.removeEventListener("beforeunload", beforeUnloadEventHandler);
        }
      }
      return newRunningProcesses;
    }
    default: {
      return runningProcesses;
    }
  }
};


export default reduceRunningProcesses;
