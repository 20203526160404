import { AvvirEvent } from "type_aliases";

export type AutodeskHubSelectedEvent = AvvirEvent<typeof AUTODESK_HUB_SELECTED, string>

const autodeskHubSelected = (hubId: string): AutodeskHubSelectedEvent => ({
  type: AUTODESK_HUB_SELECTED,
  payload: hubId
});

export const AUTODESK_HUB_SELECTED = "autodesk_hub_selected";

export default autodeskHubSelected;