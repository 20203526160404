import { AvvirEvent } from "type_aliases";
import TeamMember from "../../../models/domain/team_member";

export type TeamMembersForProjectLoadedEvent = AvvirEvent<typeof TEAM_MEMBERS_FOR_PROJECT_LOADED, { projectId: string, teamMembers: TeamMember[] }>

const teamMembersForProjectLoaded = (projectId: string, teamMembers: TeamMember[]): TeamMembersForProjectLoadedEvent => ({
  type: TEAM_MEMBERS_FOR_PROJECT_LOADED,
  payload: {
    projectId,
    teamMembers
  }
});

export const TEAM_MEMBERS_FOR_PROJECT_LOADED = "project_team_members_loaded";

export default teamMembersForProjectLoaded;
