import type { ApiPipeline } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type PipelineRunningProcessTriggeredEvent = AvvirEvent<typeof PIPELINE_RUNNING_PROCESS_TRIGGERED, ApiPipeline>

const pipelineRunningProcessTriggered = (pipeline: ApiPipeline): PipelineRunningProcessTriggeredEvent => ({
  type: PIPELINE_RUNNING_PROCESS_TRIGGERED,
  payload: pipeline
});

export const PIPELINE_RUNNING_PROCESS_TRIGGERED = "pipeline_running_process_triggered";

export default pipelineRunningProcessTriggered;
