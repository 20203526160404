import { AvvirEvent } from "type_aliases";

export type ProcoreObservationTypeSelectedEvent = AvvirEvent<typeof PROCORE_OBSERVATION_TYPE_SELECTED, any>

const procoreObservationTypeSelected = (selectedProcoreObservationTypeId): ProcoreObservationTypeSelectedEvent => ({
  type: PROCORE_OBSERVATION_TYPE_SELECTED,
  payload: selectedProcoreObservationTypeId
});

export const PROCORE_OBSERVATION_TYPE_SELECTED = "procore_observation_type_selected";

export default procoreObservationTypeSelected;

