import type { ApiRunningProcess } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type RunningProcessFailedEvent = AvvirEvent<typeof RUNNING_PROCESS_FAILED, {
  runningProcess: ApiRunningProcess
}>

const runningProcessFailed = ({ runningProcess }: { runningProcess: ApiRunningProcess }): RunningProcessFailedEvent => ({
  type: RUNNING_PROCESS_FAILED,
  payload: {
    runningProcess
  }
});

export const RUNNING_PROCESS_FAILED = "running_process_failed";

export default runningProcessFailed;
