import type { AvvirEvent, ErrorLike } from "type_aliases";

export type FileUploadFailedEvent = AvvirEvent<typeof FILE_UPLOAD_FAILED, { fileTitle: string, error: ErrorLike }>

const fileUploadFailed = (fileTitle: string, error: ErrorLike): FileUploadFailedEvent => ({
  type: FILE_UPLOAD_FAILED,
  payload: {
    fileTitle,
    error
  }
});

export const FILE_UPLOAD_FAILED = "file_upload_failed";

export default fileUploadFailed;
