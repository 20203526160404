import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type UpdateFloorMasterformatProgressStartedEvent = AvvirEvent<typeof UPDATE_FLOOR_MASTERFORMAT_PROGRESS_STARTED, {
  processId: number
  projectId: string
  floorId: string
  startTime: Date
}>

export const updateFloorMasterformatProgressStarted = ({ projectId, floorId }: AssociationIds, startTime: Date, processId: number): UpdateFloorMasterformatProgressStartedEvent => ({
  type: UPDATE_FLOOR_MASTERFORMAT_PROGRESS_STARTED,
  payload: {
    processId,
    projectId,
    floorId,
    startTime
  }
});

export const UPDATE_FLOOR_MASTERFORMAT_PROGRESS_STARTED = "update_floor_masterformat_progress_started";
