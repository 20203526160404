import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ExportedToBcfEvent = AvvirEvent<typeof EXPORTED_TO_BCF, { floorId: string, globalIds: string[] }>

const exportedToBcf = ({ floorId }: AssociationIds, globalIds: string[]): ExportedToBcfEvent => ({
  type: EXPORTED_TO_BCF,
  payload: {
    floorId,
    globalIds
  }
});

export const EXPORTED_TO_BCF = "exported_to_bcf";

export default exportedToBcf;
