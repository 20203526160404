import { Reducer } from "redux";

import { isRoutingEvent } from "../routing_events";
import { PROCORE_PROJECT_SELECTED, ProcoreProjectSelectedEvent } from "../../events/procore_project_selected";
import { PROCORE_PROJECTS_LOADED, ProcoreProjectsLoadedEvent } from "../../events/loaded/procore/procore_projects_loaded";
import { PUSH_TO_PROCORE_DIALOG_DISMISSED, PushToProcoreDialogDismissedEvent } from "../../events/viewer/push_to_procore_dialog_dismissed";
import { PUSH_TO_PROCORE_DIALOG_SHOWN, PushToProcoreDialogShownEvent } from "../../events/viewer/push_to_procore_dialog_shown";
import { PROCORE_COMPANIES_LOADED, ProcoreCompaniesLoadedEvent } from "../../events/loaded/procore/procore_companies_loaded";
import { PROCORE_OBSERVATION_TYPES_LOADED, ProcoreObservationTypesLoadedEvent } from "../../events/loaded/procore/procore_observation_types_loaded";
import { PROCORE_COMPANY_SELECTED, ProcoreCompanySelectedEvent } from "../../events/procore_company_selected";
import { PROCORE_OBSERVATION_TYPE_SELECTED, ProcoreObservationTypeSelectedEvent } from "../../events/procore_observation_type_selected";
import { PROCORE_ASSIGNEES_LOADED, ProcoreAssigneesLoadedEvent } from "../../events/loaded/procore/procore_assignees_loaded";
import { PROCORE_ASSIGNEE_SELECTED, ProcoreAssigneeSelectedEvent } from "../../events/procore_assignee_selected";

export type PushToProcoreDialogStore = {
  open: boolean
  procoreProjects?: any[]
  procoreCompanies?: any[]
  procoreObservationTypes?: any[]
  procoreAssignees?: any[]
  selectedProcoreProjectId?: any
  selectedProcoreCompanyId?: any
  selectedProcoreObservationTypeId?: any
  selectedProcoreAssigneeId?: any
  pdfType?: any
  procoreAccessToken?: any
  selectedElement?:any
}

type Events =
  | ProcoreProjectsLoadedEvent
  | ProcoreCompaniesLoadedEvent
  | ProcoreObservationTypesLoadedEvent
  | ProcoreAssigneesLoadedEvent
  | ProcoreProjectSelectedEvent
  | ProcoreCompanySelectedEvent
  | ProcoreObservationTypeSelectedEvent
  | ProcoreAssigneeSelectedEvent
  | PushToProcoreDialogDismissedEvent
  | PushToProcoreDialogShownEvent

const reducePushToProcoreDialog: Reducer<PushToProcoreDialogStore, Events> = (pushToProcoreDialog = { open: false }, event) => {
  if (isRoutingEvent(event)) {
    return { open: false };
  }
  switch (event.type) {
    case PROCORE_PROJECTS_LOADED: {
      return {
        ...pushToProcoreDialog,
        open: true,
        procoreProjects: event.payload,
        selectedProcoreProjectId: event.payload[0]?.projectId
      };
    }
    case PROCORE_COMPANIES_LOADED: {
      return {
        ...pushToProcoreDialog,
        open: true,
        procoreCompanies: event.payload,
        selectedProcoreCompanyId: event.payload[0]?.companyId
      };
    }
    case PROCORE_OBSERVATION_TYPES_LOADED: {
      return {
        ...pushToProcoreDialog,
        open: true,
        procoreObservationTypes: event.payload,
        selectedProcoreObservationTypeId: event.payload[0]?.observationTypeId
      };
    }
    case PROCORE_ASSIGNEES_LOADED: {
      return {
        ...pushToProcoreDialog,
        open: true,
        procoreAssignees: event.payload,
        selectedProcoreAssigneeId: event.payload[0]?.assigneeId
      };
    }
    case PUSH_TO_PROCORE_DIALOG_SHOWN: {
      return {
        ...pushToProcoreDialog,
        pdfType: event.payload.pdfType,
        procoreAccessToken: event.payload.procoreAccessToken,
        open: true,
        selectedElement: event.payload.selectedElement,
      };
    }
    case PUSH_TO_PROCORE_DIALOG_DISMISSED: {
      return {
        ...pushToProcoreDialog,
        pdfType: null,
        open: false,
      };
    }
    case PROCORE_PROJECT_SELECTED: {
      return {
        ...pushToProcoreDialog,
        selectedProcoreProjectId: event.payload
      };
    }
    case PROCORE_COMPANY_SELECTED: {
      return {
        ...pushToProcoreDialog,
        selectedProcoreCompanyId: event.payload
      };
    }
    case PROCORE_OBSERVATION_TYPE_SELECTED: {
      return {
        ...pushToProcoreDialog,
        selectedProcoreObservationTypeId: event.payload
      };
    }
    case PROCORE_ASSIGNEE_SELECTED: {
      return {
        ...pushToProcoreDialog,
        selectedProcoreAssigneeId: event.payload
      };
    }
    default: {
      return pushToProcoreDialog;
    }
  }
};

export default reducePushToProcoreDialog;