import _ from "underscore";
import { AssociationType } from "avvir";

import CloudFile from "../../../models/domain/cloud_file";
import FileInformationConverter from "../../converters/file_information_converter";
import makeUpdateModel from "./make_update_model";
import Project from "../../../models/domain/project";
import ProjectConverter from "../../converters/project_converter";
import ProjectCostAnalysisProgress from "../../../models/domain/progress/project_cost_analysis_progress";
import ProjectListing from "../../../models/domain/project_listing";
import ProjectReport from "../../../models/domain/project_report";
import ProjectReportVersion from "../../../models/domain/project_report_version";
import { CLASSIFICATION_CODES_LOADED, ClassificationCodesLoadedEvent } from "../../../events/loaded/classification_codes_loaded";
import { FILE_ASSOCIATIONS_UPDATED, FileAssociationsUpdatedEvent } from "../../../events/files/file_associations_updated";
import { FILE_ASSOCIATIONS_UPDATED_RETAIN_FLOOR_FILES, FileAssociationsUpdatedRetainedFloorFilesEvent } from "../../../events/files/file_associtions_updated_retain_floor_files";
import { FLOOR_CREATED, FloorCreatedEvent } from "../../../events/superadmin/floors/floor_created";
import { FLOOR_DELETED, FloorDeletedEvent } from "../../../events/superadmin/floors/floor_deleted";
import { FloorPurposeType, OTHER } from "../../../models/domain/enums/purpose_type";
import { FLOORS_FOR_PROJECT_LOADED, FloorsForProjectLoadedEvent } from "../../../events/loaded/floors/floors_for_project_loaded";
import { MASTERFORMAT_SELECTION_UPDATED, MasterformatSelectionUpdatedEvent } from "../../../events/selection/work_breakdown_structure/masterformat_selection_updated";
import { PIPELINE_STARTED, PipelineStartedEvent } from "../../../events/superadmin/pipelines/pipeline_started";
import { PIPELINE_STEPS_TRIGGERED, PipelineStepsTriggeredEvent } from "../../../events/superadmin/pipelines/pipeline_steps_triggered";
import { PIPELINE_TRIGGER_FAILED, PipelineTriggerFailedEvent } from "../../../events/superadmin/pipelines/pipeline_trigger_failed";
import { PROJECT_ARCHIVED, ProjectArchivedEvent } from "../../../events/superadmin/projects/project_archived";
import { PROJECT_COST_ANALYSIS_PROGRESS_REPORTED_QUANTITY_CHANGED, ProjectCostAnalysisProgressReportedQuantityChangedEvent } from "../../../events/project_cost_analysis_progress_reported_quantity_changed";
import { PROJECT_FILE_UPLOADED, ProjectFileUploadedEvent } from "../../../events/files/upload/project_file_uploaded";
import { PROJECT_FILES_LOADED, ProjectFilesLoadedEvent } from "../../../events/loaded/projects/project_files_loaded";
import { PROJECT_LISTINGS_LOADED, ProjectListingsLoadedEvent } from "../../../events/loaded/projects/project_listings_loaded";
import { PROJECT_LOADED, ProjectLoadedEvent } from "../../../events/loaded/projects/project_loaded";
import { PROJECT_MASTERFORMAT_PROGRESS_LOADED, ProjectMasterformatProgressLoadedEvent } from "../../../events/loaded/projects/project_masterformat_progress_loaded";
import { PROJECT_RECIPE_CREATED, ProjectRecipeCreatedEvent } from "../../../events/recipes/project_recipe_created";
import { PROJECT_RECIPE_REMOVED, ProjectRecipeRemovedEvent } from "../../../events/recipes/project_recipe_removed";
import { PROJECT_RECIPES_LOADED, ProjectRecipesLoadedEvent } from "../../../events/loaded/project_recipes_loaded";
import { PROJECT_REPORT_ADDED, ProjectReportAddedEvent } from "../../../events/superadmin/projects/project_report_added";
import { PROJECT_REPORT_REMOVED, ProjectReportRemovedEvent } from "../../../events/superadmin/projects/project_report_removed";
import { PROJECT_REPORT_UPDATED, ProjectReportUpdatedEvent } from "../../../events/superadmin/projects/project_report_updated";
import { PROJECT_REPORT_VERSION_ADDED, ProjectReportVersionAddedEvent } from "../../../events/superadmin/projects/project_report_version_added";
import { PROJECT_REPORT_VERSION_REMOVED, ProjectReportVersionRemovedEvent } from "../../../events/superadmin/projects/project_report_version_removed";
import { PROJECT_REPORT_VERSION_UPDATED, ProjectReportVersionUpdatedEvent } from "../../../events/superadmin/projects/project_report_version_updated";
import { PROJECT_UNARCHIVED, ProjectUnarchivedEvent } from "../../../events/superadmin/projects/project_unarchived";
import { PROJECT_UPDATED, ProjectUpdatedEvent } from "../../../events/superadmin/projects/project_updated";
import { PROJECT_WORK_PACKAGES_LOADED, ProjectWorkPackagesLoadedEvent } from "../../../events/loaded/projects/project_work_packages_loaded";
import { PROJECT_WORK_PACKAGES_SAVED, ProjectWorkPackagesSavedEvent } from "../../../events/loaded/projects/project_work_packages_saved";
import { PROJECTS_FOR_ORGANIZATION_LOADED, ProjectsForOrganizationLoadedEvent } from "../../../events/loaded/projects/projects_for_organization_loaded";
import { SCANNED_PROJECT_MASTERFORMAT_PROGRESS_LOADED, ScannedProjectMasterformatProgressLoadedEvent } from "../../../events/loaded/projects/scanned_project_masterformat_progress_loaded";
import { USER_PROJECTS_LOADED, UserProjectsLoadedEvent } from "../../../events/loaded/projects/user_projects_loaded";
import { without } from "../../utilities/general";
import { FILE_DELETED, FileDeletedEvent } from "../../../events/files/file_deleted";

import type { Reducer } from "redux";

type ProjectEvents =
  | ProjectLoadedEvent
  | ProjectMasterformatProgressLoadedEvent
  | ScannedProjectMasterformatProgressLoadedEvent
  | ProjectFilesLoadedEvent
  | ProjectFileUploadedEvent
  | ProjectUpdatedEvent
  | FloorCreatedEvent
  | FloorDeletedEvent
  | UserProjectsLoadedEvent
  | PipelineStartedEvent
  | ProjectsForOrganizationLoadedEvent
  | ProjectArchivedEvent
  | ProjectUnarchivedEvent
  | PipelineStepsTriggeredEvent
  | PipelineTriggerFailedEvent
  | ProjectCostAnalysisProgressReportedQuantityChangedEvent
  | ProjectReportUpdatedEvent
  | ProjectReportAddedEvent
  | ProjectReportRemovedEvent
  | ProjectReportVersionAddedEvent
  | ProjectReportVersionUpdatedEvent
  | ProjectReportVersionRemovedEvent
  | MasterformatSelectionUpdatedEvent
  | ProjectWorkPackagesLoadedEvent
  | ProjectWorkPackagesSavedEvent
  | ProjectListingsLoadedEvent
  | FileAssociationsUpdatedEvent
  | ProjectRecipesLoadedEvent
  | ProjectRecipeCreatedEvent
  | ProjectRecipeRemovedEvent
  | ClassificationCodesLoadedEvent
  | FloorsForProjectLoadedEvent
  | FileAssociationsUpdatedRetainedFloorFilesEvent
  | FileDeletedEvent;

export interface ProjectsStore {
  byFirebaseId: {
    [firebaseId: string]: Project
  };
  triggeredPipelines?: string[];
  startedPipelines?: { [key: string]: object };
  listings?: ProjectListing[];
}

const emptyProject = new Project({
  firebaseFloorIds: [],
  masterformatProgress: { scanned: {}, baseline: {}, current: {}, projectCostAnalysis: [] }
});

const updateProject = makeUpdateModel<Project>(emptyProject);


function addFileToProject(projects: ProjectsStore, projectId: string, file: CloudFile) {
  return updateProject(projects, projectId, project => {
    if (file.purposeType === FloorPurposeType.OTHER) {
      return {
        files: {
          ...project.files,
          [file.purposeType]: _.union(project.files?.[file.purposeType], [file.id])
        }
      };
    } else {
      return {
        files: {
          ...project.files,
          [file.purposeType]: [file.id]
        }
      };
    }
  });
}

function removeFileFromProject(projects: ProjectsStore, projectId: string, file: CloudFile | number) {
  if (typeof file === "number") {
    const filePurposeType = _.chain(projects.byFirebaseId[projectId]?.files)
      .pick(fileIds => fileIds.includes(file))
      .keys()
      .value()[0];
    if (filePurposeType) {
      return updateProject(projects, projectId, project => {
        return {
          files: {
            ...project.files,
            [filePurposeType]: _.without(project.files?.[filePurposeType], file)
          }
        };
      });
    } else {
      return projects;
    }
  } else {
    return updateProject(projects, projectId, project => {
      return {
        files: {
          ...project.files,
          [file.purposeType]: _.without(project.files?.[file.purposeType], file.id)
        }
      };
    });
  }
}

const reduceProjects: Reducer<ProjectsStore, ProjectEvents> = (projects: ProjectsStore = { byFirebaseId: {} }, event) => {
  if (!projects?.byFirebaseId) {
    projects = { byFirebaseId: {} };
  }
  switch (event.type) {
    case PROJECT_LOADED: {
      return updateProject(projects, event.payload.firebaseId, (currentProject) => {
        return {
          ...currentProject,
          ...ProjectConverter.fromApi(event.payload),
          masterformatProgress: currentProject.masterformatProgress
        };
      });
    }
    case PROJECT_MASTERFORMAT_PROGRESS_LOADED: {
      if (event.payload.scanned?.length || event.payload.baseline?.length || event.payload.current?.length) {
        const project = ProjectConverter.fromApi({
          scannedProjectMasterformatProgresses: event.payload.scanned,
          baselineProjectMasterformatProgresses: event.payload.baseline,
          currentProjectMasterformatProgresses: event.payload.current,
        });

        return updateProject(projects, event.payload.id, {
          masterformatProgress: project.masterformatProgress
        });
      } else {
        return projects;
      }
    }
    case SCANNED_PROJECT_MASTERFORMAT_PROGRESS_LOADED: {
      const project = ProjectConverter.fromApi({
        scannedProjectMasterformatProgresses: event.payload.scanned
      });
      return updateProject(projects, event.payload.id, (existingProject) => ({
          ...existingProject,
          masterformatProgress: {
            ...existingProject.masterformatProgress,
            scanned: project.masterformatProgress.scanned
          }
        })
      );
    }
    case PROJECT_FILES_LOADED: {
      const files = FileInformationConverter.fileIdsFromApi(event.payload);
      return updateProject(projects, event.payload.projectId, (existingProject) => ({
        files: {
          ...existingProject.files,
          ...files
        }
      }));
    }
    case PROJECT_FILE_UPLOADED: {
      const files = FileInformationConverter.fileIdsFromApi({ files: [event.payload.file] })[OTHER];
      return updateProject(projects, event.payload.projectId, (existingProject) => ({
        files: {
          ...existingProject.files,
          [OTHER]: _.union(existingProject.files?.[OTHER], files)
        }
      }));
    }
    case PROJECT_UPDATED: {
      return updateProject(projects, event.payload.project.firebaseId, (current) => {
        let newProject = {
          ...current,
          ...ProjectConverter.fromApi(ProjectConverter.toApiProject(event.payload.project)),
        };
        if (newProject.firebaseFloorIds.length === 0) {
          newProject.firebaseFloorIds = current.firebaseFloorIds;
        }
        if (event.payload.project.systemOfMeasurement == null) {
          newProject.systemOfMeasurement = current.systemOfMeasurement;
        }
        if (event.payload.project.projectReports == null) {
          newProject.projectReports = current.projectReports;
        }
        return newProject;
      });
    }
    case FLOOR_CREATED: {
      const { firebaseId: floorId } = event.payload;
      return updateProject(projects, event.payload.firebaseProjectId, (project) => ({
        firebaseFloorIds: _.union(project.firebaseFloorIds, [floorId])
      }));
    }
    case FLOOR_DELETED: {
      return updateProject(projects, event.payload.projectId, (project) => ({
        firebaseFloorIds: without(project.firebaseFloorIds, event.payload.floorId)
      }));
    }
    case USER_PROJECTS_LOADED: {
      return event.payload.reduce((projectsSoFar, project) => {
        const loadedProject = ProjectConverter.fromApi(project);
        return updateProject(projectsSoFar, loadedProject.firebaseId, (currentProject) => ({
          ...currentProject,
          ...loadedProject,
          masterformatProgress: currentProject.masterformatProgress,
          firebaseFloorIds: _.union(currentProject.firebaseFloorIds, loadedProject.firebaseFloorIds)
        }));
      }, projects);
    }
    case PIPELINE_STEPS_TRIGGERED: {
      const triggeredPipelines = projects.triggeredPipelines || [];
      return {
        ...projects,
        triggeredPipelines: [
          ...triggeredPipelines,
          `${event.payload.floorId}_${event.payload.scanDatasetId}`
        ]
      };
    }
    case PIPELINE_TRIGGER_FAILED: {
      const triggeredPipelines = projects.triggeredPipelines || [];
      return {
        ...projects,
        triggeredPipelines: _.without(triggeredPipelines, `${event.payload.floorId}_${event.payload.scanDatasetId}`)
      };
    }
    case PIPELINE_STARTED: {
      return {
        ...projects,
        triggeredPipelines: _.without(projects.triggeredPipelines, `${event.payload.floorId}_${event.payload.scanDatasetId}`),
        startedPipelines: {
          ...projects.startedPipelines,
          [`${event.payload.floorId}_${event.payload.scanDatasetId}`]: event.payload.data
        }
      };
    }
    case PROJECTS_FOR_ORGANIZATION_LOADED: {
      return event.payload.projects.reduce((projectsSoFar, project) => {
        const loadedProject = ProjectConverter.fromApi(project);
        return updateProject(projectsSoFar, loadedProject.firebaseId, loadedProject);
      }, { ...projects });
    }
    case PROJECT_ARCHIVED: {
      return updateProject(projects, event.payload.projectId, {
        archivedAt: new Date()
      });
    }
    case PROJECT_UNARCHIVED: {
      return updateProject(projects, event.payload.projectId, {
        archivedAt: null
      });
    }
    case PROJECT_REPORT_ADDED: {
      return updateProject(projects, event.payload.projectId, (existingProject) => ({
        projectReports: [
          ...existingProject.projectReports,
          event.payload.report
        ]
      }));
    }
    case PROJECT_REPORT_REMOVED: {
      return updateProject(projects, event.payload.projectId, (existingProject) => ({
        projectReports: existingProject.projectReports.filter((report) => { return report.id !== event.payload.projectReportId;})
      }));
    }
    case PROJECT_REPORT_UPDATED: {
      return updateProject(projects, event.payload.projectId, (existingProject) => {
        const reports = existingProject.projectReports.slice();
        for (let i = 0; i < reports.length; i++) {
          if (reports[i].id === event.payload.report.id) {
            reports[i] = new ProjectReport(event.payload.report);
            break;
          }
        }

        return {
          projectReports: reports
        };
      });
    }
    case PROJECT_REPORT_VERSION_UPDATED: {
      return updateProject(projects, event.payload.projectId, (existingProject) => {
        const reports = existingProject.projectReports.slice();
        const report = reports.find((report) => { return report.id === event.payload.projectReportId;});
        for (let i = 0; i < report.reportVersions.length; i++) {
          if (report.reportVersions[i].id === event.payload.reportVersion.id) {
            report.reportVersions[i] = new ProjectReportVersion(event.payload.reportVersion);
            break;
          }
        }

        return {
          projectReports: reports
        };
      });
    }
    case PROJECT_REPORT_VERSION_ADDED: {
      return updateProject(projects, event.payload.projectId, (existingProject) => {
        const reports = existingProject.projectReports.slice();
        const report = reports.find((report) => { return report.id === event.payload.projectReportId;});
        const reportVersion = new ProjectReportVersion(event.payload.reportVersion);
        if (reportVersion.reportDate == null) {
          reportVersion.reportDate = new Date();
        }

        report.reportVersions = report.reportVersions.filter((item) => { return item.id != null;});
        report.reportVersions.unshift(reportVersion);

        return {
          projectReports: reports
        };
      });
    }
    case PROJECT_REPORT_VERSION_REMOVED: {
      return updateProject(projects, event.payload.projectId, (existingProject) => {
        const reports = existingProject.projectReports.slice();
        const report = reports.find((report) => { return report.id === event.payload.projectReportId;});
        for (let i = 0; i < report.reportVersions.length; i++) {
          if (report.reportVersions[i].id === event.payload.reportVersion.id) {
            report.reportVersions.splice(i, 1);
            break;
          }
        }

        return {
          projectReports: reports
        };
      });
    }
    case MASTERFORMAT_SELECTION_UPDATED: {
      const project = ProjectConverter.fromApi({
        costAnalysisProgresses: event.payload.projectCostAnalysis
      });
      return updateProject(projects, event.payload.projectId, (currentProject) => ({
          ...currentProject,
          masterformatProgress: {
            ...currentProject.masterformatProgress,
            projectCostAnalysis: project.masterformatProgress.projectCostAnalysis
          }
        })
      );
    }
    case PROJECT_COST_ANALYSIS_PROGRESS_REPORTED_QUANTITY_CHANGED: {
      return updateProject(projects, event.payload.projectId, (currentProject) => {
        return {
          ...currentProject,
          masterformatProgress: {
            ...currentProject.masterformatProgress,
            projectCostAnalysis: {
              ...currentProject.masterformatProgress.projectCostAnalysis,
              [event.payload.costAnalysisProgressId]: new ProjectCostAnalysisProgress({
                ...currentProject.masterformatProgress.projectCostAnalysis[event.payload.costAnalysisProgressId],
                reportedQuantityProject: event.payload.value
              })
            }
          }
        };
      });
    }
    case PROJECT_WORK_PACKAGES_LOADED: {
      return updateProject(projects, event.payload.projectId, (existingProject) => ({
        ...existingProject,
        workPackages: event.payload.workPackages
      }));
    }
    case PROJECT_WORK_PACKAGES_SAVED: {
      return updateProject(projects, event.payload.projectId, (existingProject) => ({
        ...existingProject,
        workPackages: event.payload.workPackages
      }));
    }
    case PROJECT_LISTINGS_LOADED: {
      const projectListings = event.payload.projectListings;
      return {
        ...projects,
        listings: projectListings
      };
    }
    case FILE_ASSOCIATIONS_UPDATED: {
      const previousProjectFiles = event.payload.previousFiles.filter((file) => file.associationType === AssociationType.PROJECT);
      const projectFiles = event.payload.files.filter((file) => file.associationType === AssociationType.PROJECT);
      const idMap = {};
      let updatedStore = projects;

      if (projectFiles.length === 0 && previousProjectFiles.length === 0) {
        return projects;
      }

      const updateProjectIdMap = (file) => {
        let projectId = idMap[file.associationId];
        if (projectId == null) {
          const foundProject = Object.values(updatedStore.byFirebaseId).find((project) => project.id === file.associationId);
          if (foundProject != null) {
            idMap[file.associationId] = foundProject.firebaseId;
          }
        }
      };

      previousProjectFiles.forEach(updateProjectIdMap);
      projectFiles.forEach(updateProjectIdMap);

      previousProjectFiles.forEach((previousFile) => {
        const projectId = idMap[previousFile.associationId];
        if (projectId != null) {
          updatedStore = removeFileFromProject(updatedStore, projectId, new CloudFile(previousFile));
        }
      });

      projectFiles.forEach((file) => {
        const projectId = idMap[file.associationId];
        if (projectId != null) {
          updatedStore = addFileToProject(updatedStore, projectId, new CloudFile(file));
        }
      });

      return updatedStore;
    }
    case FILE_ASSOCIATIONS_UPDATED_RETAIN_FLOOR_FILES: {
      const previousProjectFiles = event.payload.previousFiles.filter((file) => file.associationType === AssociationType.FLOOR);
      const projectFiles = event.payload.files.filter((file) => file.associationType === AssociationType.PROJECT);
      let updatedStore = projects;

      if (projectFiles.length === 0 && previousProjectFiles.length === 0) {
        return projects;
      }

      projectFiles.forEach((file) => {
        const projectId = event.payload.projectId;
        if (projectId != null) {
          updatedStore = addFileToProject(updatedStore, projectId, new CloudFile(file));
        }
      });

      return updatedStore;
    }
    case FILE_DELETED: {
      return removeFileFromProject(projects, event.payload.projectId, event.payload.fileId);
    }
    case PROJECT_RECIPES_LOADED: {
      const { id: projectId, recipes } = event.payload;
      const recipeIds = recipes.map(recipe => recipe.id);
      return updateProject(projects, projectId, () => ({
        recipeIds
      }));
    }
    case PROJECT_RECIPE_CREATED: {
      const { recipe } = event.payload;
      const currentRecipes = projects.byFirebaseId[recipe.firebaseProjectId].recipeIds;
      return updateProject(projects, recipe.firebaseProjectId, () => ({
        recipeIds: [...currentRecipes, recipe.id]
      }));
    }
    case PROJECT_RECIPE_REMOVED: {
      const { id, projectId } = event.payload;
      const currentRecipes = projects.byFirebaseId[projectId].recipeIds;
      return updateProject(projects, projectId, () => ({
        recipeIds: currentRecipes.filter((currentId) => currentId !== id)
      }));
    }
    case CLASSIFICATION_CODES_LOADED: {
      const { codes, projectId } = event.payload;
      return updateProject(projects, projectId, (project) => ({
        ...project,
        classificationCodes: codes
      }));
    }
    case FLOORS_FOR_PROJECT_LOADED: {
      let projectId = event.payload[0].firebaseProjectId;
      const firebaseFloorIds = event.payload.map((floor) => floor.firebaseId);
      return updateProject(projects, projectId, (project) => ({
        ...project,
        firebaseFloorIds
      }));
    }
    default: {
      return projects;
    }
  }
};

export default reduceProjects;
