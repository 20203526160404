import { NOT_FOUND } from "redux-first-router";

import { AvvirPayloadlessRoutingEvent, AvvirRoutingEvent } from "type_aliases";
import { PROJECT_NOT_FOUND, ProjectNotFoundEvent } from "../events/notifications/failures/project_not_found";
import { TO_ACCEPT_INVITATION_PAGE, ToAcceptInvitationPageEvent } from "../events/routing/to_accept_invitation_page";
import { TO_CONFIRM_ACCOUNT_PAGE, ToConfirmAccountPageEvent } from "../events/routing/to_confirm_account_page";
import { TO_PASSWORD_RESET_CONFIRMATION_PAGE } from "../events/routing/to_password_reset_confirmation_page";
import { TO_PASSWORD_RESET_EMAIL_FORM } from "../events/routing/to_password_reset_email_form";
import { TO_PASSWORD_RESET_PAGE } from "../events/routing/to_password_reset_page";
import { TO_PHOTOS, ToPhotosEvent } from "../events/routing/to_photos_page";
import { TO_PHOTOS_FLOOR, ToPhotosFloorEvent } from "../events/routing/to_photos_floor";
import { TO_PROJECT, ToProjectEvent } from "../events/routing/to_project";
import { TO_PROJECT_PORTFOLIO_PAGE, ToProjectPortfolioPageEvent } from "../events/routing/to_project_portfolio_page";
import { TO_PROJECT_SUMMARY_PAGE, ToProjectSummaryPageEvent } from "../events/routing/to_project_summary_page";
import { TO_PROJECT_TEAM_PAGE, ToProjectTeamPageEvent } from "../events/routing/to_project_team_page";
import { TO_PROJECT_UPLOADS_PAGE, ToProjectUploadsPageEvent } from "../events/routing/to_project_uploads_page";
import { TO_SEARCH_SET_CREATOR_PAGE, ToSearchSetCreatorEvent } from "../events/routing/to_search_set_creator_page";
import { TO_SUPERADMIN_FLOOR, ToSuperadminFloorEvent } from "../events/routing/superadmin/to_superadmin_floor";
import { TO_SUPERADMIN_NEW_ORGANIZATION, ToSuperadminNewOrganizationEvent } from "../events/routing/superadmin/to_superadmin_new_organization";
import { TO_SUPERADMIN_NEW_PROJECT, ToSuperadminNewProjectEvent } from "../events/routing/superadmin/to_superadmin_new_project";
import { TO_SUPERADMIN_ORGANIZATION, ToSuperadminOrganizationEvent } from "../events/routing/superadmin/to_superadmin_organization";
import { TO_SUPERADMIN_ORGANIZATIONS, ToSuperadminOrganizationsEvent } from "../events/routing/superadmin/to_superadmin_organizations";
import { TO_SUPERADMIN_PROJECT, ToSuperadminProjectEvent } from "../events/routing/superadmin/to_superadmin_project";
import { TO_SUPERADMIN_SCAN_DATASET, ToSuperadminScanDatasetEvent } from "../events/routing/superadmin/to_superadmin_scan_datasets";
import { TO_VIEWER_FLOOR, ToViewerFloorEvent } from "../events/routing/to_viewer_floor";
import { TO_VIEWER_SCAN_DATASET, ToViewerScanDatasetEvent } from "../events/routing/to_viewer_scan_dataset";
import { TO_WBS_WITH_FLOOR, ToWbsWithFloor } from "../events/routing/to_wbs_with_floor";
import { TO_WBS_WITH_PROJECT, ToWbsWithProjectEvent } from "../events/routing/to_wbs_with_project";
import { TO_WBS_WITH_WBS_CODE, ToWbsWithWbsCode } from "../events/routing/to_wbs_with_wbs_code";
import { TO_INTEGRATION_PAGE, ToIntegrationPageEvent } from "../events/routing/to_integration_page";
import { TO_PROJECT_INTEGRATIONS_PAGE, ToProjectIntegrationsPageEvent } from "../events/routing/to_project_integrations_page";
import { TO_SUPERADMIN_ORGANIZATION_USERS, ToSuperadminOrganizationUsersEvent } from "../events/routing/superadmin/to_superadmin_organization_users";
import { TO_SUPERADMIN_SCANS_TABLE, ToSuperadminScansTableEvent } from "../events/routing/superadmin/to_superadmin_scans_table";
import { TO_SUPERADMIN_USER, ToSuperadminUserEvent } from "../events/routing/superadmin/to_superadmin_user";
import { ToProjectSettingsPageEvent } from "../events/routing/to_project_settings_page";
import { TO_PROGRESS_4D_REPORT, ToProgress4dReportEvent } from "../events/routing/to_progress_4d_report";
import { TO_PROGRESS_4D_REPORT_FOR_AREA, ToProgress4dReportForAreaEvent } from "../events/routing/to_progress_4d_report_for_area";
import { TO_CUSTOM_REPORT, ToCustomReportEvent } from "../events/routing/to_custom_report";
import { TO_CUSTOM_REPORTS, ToCustomReportsEvent } from "../events/routing/to_custom_reports";
import { TO_INSPECT_REPORT, ToInspectReportEvent } from "../events/routing/to_inspect_report";
import { TO_INSPECT_REPORTS, ToInspectReportsEvent } from "../events/routing/to_inspect_reports";
import { ToSummaryProgress4dReportEvent } from "../events/routing/to_summary_progress_4d_report";
import { TO_METRICS_PAGE, ToMetricsPageEvent } from "../events/routing/to_metrics_page";
import { TO_HX_AUTH_LOGIN_PAGE, ToHxAuthLoginPageEvent } from "../events/routing/to_hx_auth_login_page";
import { TO_LOGIN_PAGE } from "../events/routing/to_login_page";
import { TO_PHOTO_PAGE } from "../events/routing/to_photo_page";

export const TO_ROOT_PAGE = "to_root_page";

export type NonAssociationRoutingEventType =
// TODO: Should these strings live elsewhere?
  | "to_bimtrack_authenticated_page"
  | "to_procore_authenticated_page"
  | typeof NOT_FOUND
  | typeof PROJECT_NOT_FOUND
  | typeof TO_ACCEPT_INVITATION_PAGE
  | typeof TO_CONFIRM_ACCOUNT_PAGE
  | typeof TO_LOGIN_PAGE
  | typeof TO_HX_AUTH_LOGIN_PAGE
  | typeof TO_PASSWORD_RESET_CONFIRMATION_PAGE
  | typeof TO_PASSWORD_RESET_EMAIL_FORM
  | typeof TO_PASSWORD_RESET_PAGE
  | typeof TO_PROJECT_PORTFOLIO_PAGE
  | typeof TO_ROOT_PAGE
  | typeof TO_SEARCH_SET_CREATOR_PAGE
  | typeof TO_SUPERADMIN_NEW_ORGANIZATION
  | typeof TO_SUPERADMIN_ORGANIZATIONS
  | typeof TO_SUPERADMIN_USER

export type OrganizationRoutingEventType =
  | typeof TO_SUPERADMIN_NEW_PROJECT
  | typeof TO_SUPERADMIN_ORGANIZATION
  | typeof TO_SUPERADMIN_ORGANIZATION_USERS
  | typeof TO_SUPERADMIN_SCANS_TABLE

export type ProjectRoutingEventType =
  | typeof TO_PROJECT_INTEGRATIONS_PAGE
  | typeof TO_INTEGRATION_PAGE
  | typeof TO_PHOTOS
  | typeof TO_PHOTO_PAGE
  | typeof TO_PROJECT
  | typeof TO_PROJECT_PORTFOLIO_PAGE
  | typeof TO_PROGRESS_4D_REPORT
  | typeof TO_PROGRESS_4D_REPORT_FOR_AREA
  | typeof TO_CUSTOM_REPORT
  | typeof TO_CUSTOM_REPORTS
  | typeof TO_PROJECT_SUMMARY_PAGE
  | typeof TO_PROJECT_TEAM_PAGE
  | typeof TO_PROJECT_UPLOADS_PAGE
  | typeof TO_SUPERADMIN_PROJECT
  | typeof TO_WBS_WITH_PROJECT
  | typeof TO_WBS_WITH_WBS_CODE
  | typeof TO_INSPECT_REPORT
  | typeof TO_INSPECT_REPORTS
  | typeof TO_METRICS_PAGE

export type FloorRoutingEventType =
  | typeof TO_PHOTOS_FLOOR
  | typeof TO_SUPERADMIN_FLOOR
  | typeof TO_VIEWER_FLOOR
  | typeof TO_WBS_WITH_FLOOR

export type ScanDatasetRoutingEventType =
  | typeof TO_SUPERADMIN_SCAN_DATASET
  | typeof TO_VIEWER_SCAN_DATASET

export type RoutingEventType =
  | NonAssociationRoutingEventType
  | OrganizationRoutingEventType
  | ProjectRoutingEventType
  | FloorRoutingEventType
  | ScanDatasetRoutingEventType

export type RoutingEventWithOrganizationId =
  | ToSuperadminFloorEvent
  | ToSuperadminNewProjectEvent
  | ToSuperadminOrganizationEvent
  | ToSuperadminOrganizationUsersEvent
  | ToSuperadminProjectEvent
  | ToSuperadminScanDatasetEvent

export type RoutingEventWithProjectId =
  | ToIntegrationPageEvent
  | ToPhotosEvent
  | ToPhotosFloorEvent
  | ToProjectEvent
  | ToProjectIntegrationsPageEvent
  | ToProjectPortfolioPageEvent
  | ToProgress4dReportEvent
  | ToProgress4dReportForAreaEvent
  | ToCustomReportEvent
  | ToCustomReportsEvent
  | ToProjectSettingsPageEvent
  | ToProjectSummaryPageEvent
  | ToProjectTeamPageEvent
  | ToProjectUploadsPageEvent
  | ToSuperadminFloorEvent
  | ToSuperadminProjectEvent
  | ToSuperadminScanDatasetEvent
  | ToViewerFloorEvent
  | ToViewerScanDatasetEvent
  | ToWbsWithProjectEvent
  | ToWbsWithWbsCode
  | ToInspectReportEvent
  | ToInspectReportsEvent
  | ToSummaryProgress4dReportEvent
  | ToMetricsPageEvent

export type RoutingEventWithFloorId =
  | ToPhotosFloorEvent
  | ToProgress4dReportForAreaEvent
  | ToSuperadminFloorEvent
  | ToSuperadminScanDatasetEvent
  | ToViewerFloorEvent
  | ToViewerScanDatasetEvent
  | ToWbsWithFloor

export type RoutingEventWithScanDatasetId =
  | ToSuperadminScanDatasetEvent
  | ToViewerScanDatasetEvent

type RoutingEvent =
  | AvvirPayloadlessRoutingEvent<typeof NOT_FOUND>
  | AvvirPayloadlessRoutingEvent<typeof TO_LOGIN_PAGE>
  | AvvirPayloadlessRoutingEvent<typeof TO_PASSWORD_RESET_CONFIRMATION_PAGE>
  | AvvirPayloadlessRoutingEvent<typeof TO_PASSWORD_RESET_EMAIL_FORM>
  | AvvirPayloadlessRoutingEvent<typeof TO_PASSWORD_RESET_PAGE>
  | AvvirPayloadlessRoutingEvent<typeof TO_ROOT_PAGE>
  | AvvirRoutingEvent<"to_bimtrack_authenticated_page", any>
  | AvvirRoutingEvent<"to_procore_authenticated_page", any>
  | AvvirRoutingEvent<"to_autodesk_authenticated_page", any>
  | ToHxAuthLoginPageEvent
  | ProjectNotFoundEvent
  | ToAcceptInvitationPageEvent
  | ToConfirmAccountPageEvent
  | ToSearchSetCreatorEvent
  | ToSuperadminNewOrganizationEvent
  | ToSuperadminOrganizationsEvent
  | ToSuperadminScansTableEvent
  | ToSuperadminUserEvent
  | RoutingEventWithOrganizationId
  | RoutingEventWithProjectId
  | RoutingEventWithFloorId
  | RoutingEventWithScanDatasetId

export default RoutingEvent;

export type ViewerRoutingEventType =
  | typeof TO_VIEWER_SCAN_DATASET
  | typeof TO_VIEWER_FLOOR
  | typeof TO_PROJECT;

const viewerRoutingEventTypes: Set<ViewerRoutingEventType> = new Set([TO_VIEWER_SCAN_DATASET, TO_VIEWER_FLOOR, TO_PROJECT])

export type ViewerRoutingEvent =
  | ToViewerScanDatasetEvent
  | ToViewerFloorEvent
  | ToProjectEvent

export const isViewerRoutingEvent = (event): event is ViewerRoutingEvent => viewerRoutingEventTypes.has(event.type)

export const isRoutingEvent = (event): event is RoutingEvent => event?.type?.startsWith("to_");
