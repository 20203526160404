
import ProcoreApi from "../../../services/procore_api_services/procore_api";
import pushToProcoreDialogShown from "../../../events/viewer/push_to_procore_dialog_shown";

const getProcoreAccessToken = (pdfType, selectedElement=null) => {
  return async (dispatch, getState) => {
    const state = getState();
    const procoreAccessToken = window.localStorage["procore-access-token"];

    const lookForAccessToken = () => {
      const procoreAccessToken = window.localStorage["procore-access-token"];
      if (procoreAccessToken) {
        window.removeEventListener("storage", lookForAccessToken);
        return dispatch(pushToProcoreDialogShown(procoreAccessToken, pdfType, selectedElement))
      }
    };
    const FourteenMinutesInMillis = 14 * 60 * 1000;

    if (procoreAccessToken) {
      let tokenValid=false;
      let tokenCreatedTime=window.localStorage["procore-access-token-creation-time"];
      if(tokenCreatedTime) {
        let tokenAge= Date.now()-parseInt(tokenCreatedTime);
        if(tokenAge <= FourteenMinutesInMillis){
          tokenValid=true;
        }
      }
      if(tokenValid){
        dispatch(pushToProcoreDialogShown(procoreAccessToken, pdfType, selectedElement));
      }
      else{
        window.localStorage.removeItem("procore-access-token");
        window.localStorage.removeItem("procore-access-token-creation-time");
        window.open(ProcoreApi.getLoginUrl({ pdfType }));
        window.addEventListener("storage", lookForAccessToken);
        lookForAccessToken(); // must be after the addEventListener so it can remove it
      }

    } else {
      window.open(ProcoreApi.getLoginUrl({ pdfType }));
      window.addEventListener("storage", lookForAccessToken);
      lookForAccessToken(); // must be after the addEventListener so it can remove it
    }
  }

};
export default getProcoreAccessToken;
