import { IJsonModel, IJsonTabNode, IJsonTabSetNode } from "flexlayout-react";

import { PanelFlags } from "../models/domain/enums/panel_flag";
import PanelType from "../models/domain/enums/panel_type";

export default function getProjectSummaryPanelsLayout(flags: PanelFlags, tabEnableClose: boolean = true): IJsonModel {
  let mainTabset: IJsonTabNode[] = [];
  let rightRowChildren: IJsonTabSetNode[] = [];

  if (flags.customReportsPanel) {
    mainTabset.push({
      type: "tab",
      name: "Inspect Reports",
      component: PanelType.INSPECT_REPORTS
    });
  }

  if (flags.showProgressOnSummary) {
    mainTabset.push({
      type: "tab",
      name: "Schedule vs Progress",
      component: PanelType.PROGRESS_4D_REPORT_PANEL
    });
  }

  if (flags.showCustomReportsOnSummary) {
    mainTabset.push({
      type: "tab",
      name: "Reports",
      component: PanelType.CUSTOM_REPORTS_PANEL
    });
  }

  if (flags.projectMetrics) {
    mainTabset.push({
      type: "tab",
      name: "Metrics",
      component: PanelType.PROJECT_METRICS_PANEL
    });
  }

  if (flags.projectAreaSummaryPanel) {
    mainTabset.push({
      type: "tab",
      name: "Summary View",
      component: PanelType.PROJECT_AREAS_MODEL_VIEWER
    });
    rightRowChildren.push({
      type: "tabset",
      tabLocation: "top",
      weight: 100,
      children: [
        {
          type: "tab",
          name: "Area Summary",
          component: PanelType.PROJECT_AREA_SUMMARY,
        },
      ],
    })
  }

  rightRowChildren.push({
    type: "tabset",
    tabLocation: "top",
    children: [
      {
        type: "tab",
        name: "Files",
        component: PanelType.PROJECT_FILES
      },
    ],
  });

  return {
    global: {
      tabClassName: "ProjectSummaryPage-tab",
      tabEnableClose
    },
    borders: [],
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 80,
          children: mainTabset,
        },
        {
          type: "row",
          weight: 20,
          children: rightRowChildren,
        }
      ]
    }
  };
}
