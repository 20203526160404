import type ProjectReportVersion from "../../../models/domain/project_report_version";
import type { AssociationIds } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ProjectReportVersionRemovedEvent = AvvirEvent<typeof PROJECT_REPORT_VERSION_REMOVED, {
  projectId: string,
  projectReportId: number,
  reportVersion: ProjectReportVersion
}>

const projectReportVersionRemoved = ({ projectId, projectReportId }: AssociationIds & { projectReportId: number }, reportVersion: ProjectReportVersion): ProjectReportVersionRemovedEvent => ({
  type: PROJECT_REPORT_VERSION_REMOVED,
  payload: {
    projectId,
    projectReportId,
    reportVersion
  }
});

export const PROJECT_REPORT_VERSION_REMOVED = "project_report_version_removed";

export default projectReportVersionRemoved;
