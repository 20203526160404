import type { AvvirEvent } from "type_aliases";

export type FloorDeletedEvent = AvvirEvent<typeof FLOOR_DELETED, { projectId: string, floorId: string, scanDatasetId: string }>

const floorDeleted = (projectId: string, floorId: string, scanDatasetId: string): FloorDeletedEvent => ({
  type: FLOOR_DELETED,
  payload: {
    projectId,
    floorId,
    scanDatasetId,
  }
});

export const FLOOR_DELETED = "floor_deleted";

export default floorDeleted;
