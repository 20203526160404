import { produce } from "immer";
import _ from "underscore";

import TeamMember from "../../../models/domain/team_member";
import { TEAM_MEMBERS_FOR_PROJECT_LOADED, TeamMembersForProjectLoadedEvent } from "../../../events/loaded/projects/team_members_for_project_loaded";
import { TEAM_MEMBERS_UPDATED, TeamMembersUpdatedEvent } from "../../../events/project_settings/team_members_updated";

import type { Reducer } from "redux";
import { TEAM_MEMBERS_CREATED, TeamMembersCreatedEvent } from "../../../events/project_settings/team_members_created";

type TeamMemberEvents =
  | TeamMembersForProjectLoadedEvent
  | TeamMembersUpdatedEvent
  | TeamMembersCreatedEvent;

export type TeamMembersStore = {
  byFirebaseProjectId: {
    [firebaseProjectId: string]: {
      byId: {
        [id: number]: TeamMember
      }
    }
  },
}

const reduceTeamMembers: Reducer<TeamMembersStore, TeamMemberEvents> = (teamMembers = { byFirebaseProjectId: {} }, event) => {
  return produce<TeamMembersStore>(teamMembers, (teamMembersDraft) => {
    if (!teamMembersDraft.byFirebaseProjectId) {
      teamMembersDraft.byFirebaseProjectId = {};
    }
    switch (event.type) {
      case TEAM_MEMBERS_FOR_PROJECT_LOADED: {
        if (!teamMembersDraft.byFirebaseProjectId[event.payload.projectId]) {
          teamMembersDraft.byFirebaseProjectId[event.payload.projectId] = { byId: {} };
        }

        teamMembersDraft.byFirebaseProjectId[event.payload.projectId].byId = _.chain(event.payload.teamMembers)
          .map(member => new TeamMember(member))
          .indexBy("id")
          .value();
        return teamMembersDraft;
      }
      case TEAM_MEMBERS_UPDATED: {
        if (!teamMembersDraft.byFirebaseProjectId[event.payload.projectId]) {
          teamMembersDraft.byFirebaseProjectId[event.payload.projectId] = { byId: {} };
        }

        event.payload.teamMembers.forEach(member => {
          teamMembersDraft.byFirebaseProjectId[event.payload.projectId].byId[member.id] = new TeamMember(member);
        });
        return teamMembersDraft;
      }
      case TEAM_MEMBERS_CREATED: {
        if (!teamMembersDraft.byFirebaseProjectId[event.payload.projectId]) {
          teamMembersDraft.byFirebaseProjectId[event.payload.projectId] = { byId: {} };
        }

        event.payload.teamMembers.forEach(member => {
          teamMembersDraft.byFirebaseProjectId[event.payload.projectId].byId[member.id] = new TeamMember(member);
        });

        return teamMembersDraft;
      }
      default: {
        return teamMembersDraft;
      }
    }
  });
};

export default reduceTeamMembers;
