import { Container, IPointData, IRendererOptions, Matrix, MIPMAP_MODES, Rectangle, Renderer, SCALE_MODES, settings } from "pixi.js";
import { skipHello } from "@pixi/utils";
import { Matrix3, Vector2 } from "three";

import camerasController from "../../../../services/utilities/threejs_utilities/cameras_controller";
import ClashingElementsContainer from "./clashing_elements_layer/clashing_elements_container";
import ClashingElementSprite from "./clashing_elements_layer/clashing_element_sprite";
import getPhotoViewerCamera from "../../../../services/get_photo_viewer_camera";
import PhotoLocation from "../../../../models/domain/photos/photo_location";
import PlannedBuildingElement from "../../../../models/domain/planned_building_element";
import SharedViewMarker from "./shared_views_layer/shared_view_marker";
import SharedViewsContainer from "./shared_views_layer/shared_views_container";
import { ApiView } from "avvir";
import { calculateScreenSpaceScale } from "../../../../services/utilities/pixi";
import { CameraFovMarker, FovMarkerApp } from "./fov_markers_layer/camera_fov_marker";
import { FloorPlanLayer } from "./floor_plan_layer/floor_plan_layer";
import { MapLayer } from "./floor_plan_layer/map_layer";
import { maxSafeVector2, minSafeVector2, transformVector } from "../../../../services/utilities/threejs_utilities/transform_utilities";
import { PhotoLocationMovedPayload } from "../../../../events/viewer/photo_locations_moved";
import { PhotoMinimapStage } from "./layers_shared/photo_minimap_stage";
import { PhotoSprite } from "./photos_layer/photo_sprite";
import { PhotoSpriteStyles } from "./photos_layer/photo_sprite_styles";
import { PixiPhotosLayer } from "./photos_layer/photos_layer";
import { PixiZoomContainer } from "../../../../services/utilities/pixi/pixi_zoom_container";
export const SMALL_MINIMAP_SIZE = { x: 280, y: 280 };

// These are mostly to guard against the minimap expanding indefinitely and causing an infinite resize sometimes
const MAX_HEIGHT = 2048;
const MAX_WIDTH = 2048;


export enum PixiMinimapMode {
  VIEW,
  ADJUST
}

export interface PixiMinimapBimBounds {
  minBimPosition: Vector2;
  maxBimPosition: Vector2;
  floorElevation: number;
  offsetYaw: number;
}

export interface PixiMinimapTransforms {
  mapBounds: Rectangle;
  photoAreaToBimTransform: Matrix3;
  bimToPhotoAreaTransform: Matrix3;
  viewerToPhotoAreaTransform: Matrix3;
  mapScale: number;
}

export interface PixiMinimapAppOptions {
  sharedViewsLayer?: SharedViewsContainer;
  clashingElementsLayer?: ClashingElementsContainer;
  stage?: PhotoMinimapStage;
  floorPlanLayer?: FloorPlanLayer;
  bimMinimapLayer?: MapLayer;
  zoomContainer?: PixiZoomContainer;
  bimCameraFovMarker?: CameraFovMarker;
  photoCameraFovMarker?: CameraFovMarker;
  photosLayer?: PixiPhotosLayer;
  showOrientation?: boolean;
  styles?: PhotoSpriteStyles;
  onSelectSharedView: (viewId: number) => void;
  onSelectClashingElement: (element: PlannedBuildingElement) => void;
  renderer?: Renderer;
}

export class PixiMinimapApp implements FovMarkerApp,
  PixiMinimapBimBounds,
  PixiMinimapTransforms {

  // This exists to save the size when the renderer doesn't exist yet
  private _size: { x: number, y: number };
  private _selectedPhotoLocationId: number;
  private _floorGlobalOffsetYaw: number = 0;
  private _bimToPhotoAreaTransform: Matrix3 = new Matrix3();
  private _photoAreaToBimTransform: Matrix3 = new Matrix3();
  private _photoAreaToViewerTransform: Matrix3 = new Matrix3();
  private _currentFrame: number = 0;
  private _requestedFrames: number = 1;
  private _needsRescale: boolean = false;
  private _mode: PixiMinimapMode = PixiMinimapMode.VIEW;

  private readonly aspectContainer: Container;
  private readonly floorPlanLayer: FloorPlanLayer;
  private readonly bimCameraFovMarker: CameraFovMarker;
  private readonly photoCameraFovMarker: CameraFovMarker;

  readonly stage: PhotoMinimapStage;
  readonly bimMinimapLayer: MapLayer;
  readonly clashingElementsLayer: ClashingElementsContainer;
  readonly zoomContainer: PixiZoomContainer;
  readonly photosLayer: PixiPhotosLayer;
  readonly styles: PhotoSpriteStyles;

  public sharedViewsLayer: SharedViewsContainer;
  public renderer: Renderer;
  public ready: boolean = false;
  public floorElevation: number = 0;
  public minBimPosition: Vector2 = minSafeVector2();
  public maxBimPosition: Vector2 = maxSafeVector2();
  public _viewerToPhotoAreaTransform: Matrix3 = new Matrix3();
  public mapScale: number;

  constructor(opts: PixiMinimapAppOptions) {
    this.stage = opts.stage || new PhotoMinimapStage();
    this.floorPlanLayer = opts.floorPlanLayer || new FloorPlanLayer();
    this.bimMinimapLayer = opts.bimMinimapLayer || new MapLayer();
    this.bimCameraFovMarker = opts.bimCameraFovMarker || new CameraFovMarker({
      getCamera: () => {
        return window.Avvir.ForgeViewer.getCamera();
      },
      color: 0x4a90e2
    });
    // noinspection MagicNumberJS
    this.photoCameraFovMarker = opts.photoCameraFovMarker || new CameraFovMarker({
      getCamera: getPhotoViewerCamera,
      fovOffset: -0.6666,
      color: 0x999999
    });
    this.zoomContainer = opts.zoomContainer || new PixiZoomContainer({ min: 0.5, max: 10, step: -0.001 });
    this.renderer = opts.renderer;
    this.aspectContainer = new Container();

    SharedViewMarker.spriteStyles.defaultTexture = null;
    ClashingElementSprite.spriteStyles.defaultTexture = null;
    this.styles = opts.styles || new PhotoSpriteStyles(false);
    this.photosLayer = opts.photosLayer || new PixiPhotosLayer(this.styles);
    this.sharedViewsLayer = opts.sharedViewsLayer || new SharedViewsContainer(this, (mapMarker: SharedViewMarker) => opts.onSelectSharedView(mapMarker.sharedView.id));
    this.clashingElementsLayer = opts.clashingElementsLayer || new ClashingElementsContainer(this, opts.onSelectClashingElement);

    this.stage.addChild(this.aspectContainer);
    this.aspectContainer.addChild(this.zoomContainer);
    this.zoomContainer.addChild(this.floorPlanLayer);
    this.floorPlanLayer.addChild(this.bimMinimapLayer);
    this.floorPlanLayer.addChild(this.photosLayer);
    this.floorPlanLayer.addChild(this.sharedViewsLayer);
    this.floorPlanLayer.addChild(this.clashingElementsLayer);
    this.floorPlanLayer.addChild(this.photoCameraFovMarker);
    this.floorPlanLayer.addChild(this.bimCameraFovMarker);

    this._size = { x: SMALL_MINIMAP_SIZE.x, y: SMALL_MINIMAP_SIZE.y };
  }

  destroy() {
    if (this.renderer != null) {
      this.stage.destroy(true);
      this.renderer.destroy(true);
    }
  }

  init(opts: IRendererOptions) {
    // Having to call this to suppress an over the top console message is ridiculous
    skipHello();

    // this is the critical setting to make the map textures less pixelated when zooming
    settings.MIPMAP_TEXTURES = MIPMAP_MODES.ON;

    // linear is the default but setting these shouldn't hurt
    settings.SCALE_MODE = SCALE_MODES.LINEAR;
    // noinspection MagicNumberJS
    settings.ANISOTROPIC_LEVEL = 16;


    if (opts.width == null) {
      opts.width = this._size.x;
    }

    if (opts.height == null) {
      opts.height = this._size.y;
    }

    // Ignore if there's no WebGL support for testing purposes. Our app requires it anyway for the 3d viewer.
    if (!window.WebGLRenderingContext && !this.renderer) {
      return;
    }

    this.renderer = this.renderer || new Renderer(opts);
    this.styles.init(this);
    SharedViewMarker.spriteStyles.renderMarkersToTextures(this.renderer);
    ClashingElementSprite.spriteStyles.renderMarkersToTextures(this.renderer);
    this.floorPlanLayer.init(this);
    this.stage.init(this, this.floorPlanLayer);
    this.onSizeChange(this._size.x, this._size.y);
  }

  get showOrientation() {
    return this.styles.showOrientation;
  }

  set floorGlobalOffsetYaw(yaw: number) {
    if (yaw == null) {
      return;
    }
    this._floorGlobalOffsetYaw = yaw;
    this.requestRender();
  }

  imageElement(element: HTMLImageElement) {
    this.floorPlanLayer.loadFromImageElement(element);
  }

  setBimMinimapImageElement(element: HTMLImageElement) {
    this.bimMinimapLayer.loadFromImageElement(element);
  }

  get mapBounds(): Rectangle {
    const size = this.floorPlanLayer.textureSize();
    return size == null ? this.stage.getLocalBounds() : size;
  }

  get offsetYaw() {
    return this._floorGlobalOffsetYaw;
  }

  onSizeChange(width: number, height: number, shouldRescale?: boolean) {
    this.renderer.resize(width, height);
    const textureSize = this.floorPlanLayer.textureSize();
    if (textureSize && (shouldRescale == null || shouldRescale)) {
      this.onTextureSizeChange(textureSize);
    }

    this.rescale();
    this.photosLayer.needsRender = true;
    this.requestRender();
  }

  photos(photos: PhotoLocation[]) {
    this.photosLayer.photos = photos;
    this._needsRescale = true;
  }

  render() {
    if (this.renderer == null) {
      return;
    }

    this.update();

    if (this._needsRescale) {
      this.rescale();
      this._needsRescale = false;
    }

    if (this._requestedFrames > 0) {
      this.renderer.render(this.stage);
      this._requestedFrames = Math.max(this._requestedFrames - 1, 0);

      this._currentFrame++;

      if (!this.ready) {
        if (this.photosLayer.hasPhotos()
            && this.bimCameraFovMarker.visible
            && this.floorPlanLayer.textureValid()) {
          this.rescale();
          this.requestRender();
          this.ready = true;
          this.renderer.view.setAttribute("data-is-ready", "true");
        }
      }
    }
  }

  get mode() {
    return this._mode;
  }

  set mode(value: PixiMinimapMode) {
    this._mode = value;
    this.photosLayer.mode = value;
  }

  selectedPhotoLocationId(id: number) {
    const locs = this.photosLayer;
    locs.selectedPhotoLocationId = id;
    this._selectedPhotoLocationId = id;
  }

  selectPhotoLocationCallback(callback: (id: number, fenceSelectedPhotoLocationIds?: number[]) => any) {
    this.photosLayer.selectPhotoLocation = (id: number, fenceSelectedPhotoLocationIds?: number[]) => {
      callback(id, fenceSelectedPhotoLocationIds);
      this.photosLayer.selectedPhotoLocationId = id;
      this.floorPlanLayer.stopDragging();
      this.requestRender();
    };
    this.photosLayer.needsRender = true;
  }

  updatePhotoLocations(fn: (payload: PhotoLocationMovedPayload) => any) {
    this.photosLayer.updatePhotoLocations = (payload: PhotoLocationMovedPayload) => {
      fn(payload);
      this.floorPlanLayer.stopDragging();
      this.requestRender();
    };
    this.photosLayer.needsRender = true;
  }

  updateLayers(layers) {
    this.photosLayer.visible = !!layers.photos?.enabled;
    this.photosLayer.needsRender = true;
    this.sharedViewsLayer.visible = !!layers.sharedViews?.enabled;
    this.clashingElementsLayer.visible = !!layers.clashes?.enabled;
    this.bimMinimapLayer.visible = !layers.floorPlan?.enabled;
    this.rescale();
    this.requestRender();
  }

  updateSharedViews(sharedViews: ApiView[]) {
    this.sharedViewsLayer.updateSharedViews(sharedViews, this);
    this.rescale();
    this.requestRender();
  }

  updateClashingElements(clashingElements: PlannedBuildingElement[]) {
    this.clashingElementsLayer.updateClashingElements(clashingElements, this);
    this.rescale();
    this.requestRender();
  }

  size(width?: number, height?: number) {
    const w = Math.min((width == null || width <= 0) ? SMALL_MINIMAP_SIZE.x : width, MAX_HEIGHT);
    const h = Math.min((height == null || height <= 0) ? SMALL_MINIMAP_SIZE.y : height, MAX_WIDTH);
    if (this.renderer == null) {
      return;
    }

    const xChanged = this._size.x !== w;
    const yChanged = this._size.y !== h;
    if (xChanged || yChanged) {
      this._size = { x: w, y: h };
      this.onSizeChange(w, h, xChanged && yChanged);
    }
  }

  update() {
    this.floorPlanLayer.update(this);
    if (this._selectedPhotoLocationId == null) {
      this.photoCameraFovMarker.visible = false;
    } else {
      this.photoCameraFovMarker.update(this);
    }
    this.bimCameraFovMarker.update(this);
    this.photosLayer.update(this);
    this.clashingElementsLayer.update(this);
  }

  rescale() {
    const scale = calculateScreenSpaceScale(this.floorPlanLayer);
    const s = scale.x;
    this.mapScale = s;
    // These are not in the if statement because they have side effects and short circuit evaluation might skip them
    const bimFovChanged = this.bimCameraFovMarker.zoom(s);
    const photoFovChanged = this.photoCameraFovMarker.zoom(s);
    const locationsChanged = this.photosLayer.zoom(s);
    this.sharedViewsLayer.rescaleSprites(s);
    this.clashingElementsLayer.rescaleSprites(s);

    if (bimFovChanged || photoFovChanged || locationsChanged) {
      this.requestRender();
    }
  }

  onTextureSizeChange(bounds: Rectangle) {
    this.aspectContainer.scale.set(this.screen.width / bounds.width);
    this.floorPlanLayer.updatePosition(this.screen);
    this.rescale();
  }

  updateBimBounds(minimum: Vector2, maximum: Vector2) {
    const min = minimum == null ? minSafeVector2() : minimum;
    const max = maximum == null ? maxSafeVector2() : maximum;

    if (min.x > max.x) {
      const t = min.x;
      min.x = max.x;
      max.x = t;
    }

    if (min.y > max.y) {
      const t = min.y;
      min.y = max.y;
      max.y = t;
    }

    this.minBimPosition = min;
    this.maxBimPosition = max;
  }

  get screen() {
    return this.renderer.screen;
  }

  get viewerToPhotoAreaTransform() {
    return this._viewerToPhotoAreaTransform;
  }

  get photoAreaToViewerTransform() {
    return this._photoAreaToViewerTransform;
  }

  set photoAreaToViewerTransform(newTransform: Matrix3) {
    if (newTransform == null) {
      return;
    }

    this._photoAreaToViewerTransform = newTransform.clone();

    let transform = new Matrix3();
    if (newTransform.determinant() !== 0) {
      transform.getInverse(newTransform.clone());
    }
    this._viewerToPhotoAreaTransform = transform;
    this.requestRender(10);
  }

  get photoAreaToBimTransform() {
    return this._photoAreaToBimTransform;
  }

  set photoAreaToBimTransform(newTransform: Matrix3) {
    if (newTransform == null) {
      return;
    }
    this._photoAreaToBimTransform = newTransform.clone();
    this.requestRender(10);
  }

  get bimToPhotoAreaTransform() {
    return this._bimToPhotoAreaTransform;
  }

  set bimToPhotoAreaTransform(newTransform: Matrix3) {
    if (newTransform == null) {
      return;
    }
    this._bimToPhotoAreaTransform = newTransform.clone();
    this.requestRender(5);
    this.photosLayer.onBimToPhotoAreaUpdate();
  }

  requestRender(frameCount?: number) {
    const frames = frameCount && frameCount > 0 ? frameCount : 2;
    if (this._requestedFrames < frames) {
      this._requestedFrames = frames;
    }
  }

  zoom(zoomDelta: number, screenPoint?: IPointData): IPointData | undefined {
    const point = screenPoint == null ? {
      x: this.screen.width / 2,
      y: this.screen.height / 2
    } : screenPoint;

    const offset = this.zoomContainer.zoom(zoomDelta, point);
    this.rescale();
    if (offset) {
      this.floorPlanLayer.updatePosition(this.screen, {
        x: this.floorPlanLayer.x + offset.x,
        y: this.floorPlanLayer.y + offset.y
      });
    }
    this.photosLayer.needsRender = true;

    return offset;
  }

  /**
   * Moves bim camera to the location the photo for side-by-side viewing and photo camera (so nearby-photo donuts display)
   * @param photo (Sprite)
   */
  moveCamerasToPhotoLocation(photo: PhotoSprite) {
    try {
      const viewerPos = transformVector(new Vector2(photo.position.x / this.mapBounds.width, photo.position.y / this.mapBounds.height), this.photoAreaToViewerTransform);
      camerasController.getBimViewerControls().moveCameraToPositionWithSameOrientation(viewerPos);
      camerasController.getBimViewerControls().update();
      camerasController.updatePhotoCameraCallback(viewerPos, photo.orientation);
    } catch (e) {
      // instead of logging 1000s of errors when something isn't set up yet.
    }
  }

  moveBimCameraToMapLocation(canvasLocation: IPointData) {
    // @ts-ignore
    if (!camerasController.bimViewerControls?.viewer?.navigation) {
      // the 3D viewer panel is turned off / not loaded. (prob there is a better way to check for this.)
      return;
    }
    let mapLocation = this.floorPlanLayer.toLocal(canvasLocation, this.stage);
    const viewerPos = transformVector(new Vector2(mapLocation.x / this.mapBounds.width, mapLocation.y / this.mapBounds.height), this.photoAreaToViewerTransform);
    camerasController.getBimViewerControls().moveCameraToPositionWithSameOrientation(viewerPos);
    camerasController.getBimViewerControls().update();
  }

  setBimMinimapPixelToPhotoAreaMinimapPixelTransform(newTransform: Matrix3) {
    if (newTransform == null) {
      return;
    }

    // NOTE(dlb): You can thank Canvas2D's ridiculous transform API for this nonsense
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/transform
    let [m11, m12, , m21, m22, , dx, dy, w] = newTransform.elements;

    /*    | xScale | xSkew  | tx|
          | ySkew  | yScale | ty|
          | 0      | 0      | 1 |
        */

    const xScale = m11 / w;
    const ySkew = m12 / w;
    const xSkew = m21 / w;
    const yScale = m22 / w;
    dx = dx / w;
    dy = dy / w;
    const matrix = new Matrix(xScale, ySkew, xSkew, yScale, dx, dy);
    this.requestRender(5);
    this.bimMinimapLayer.transform.setFromMatrix(matrix);
  }
}

