import { AvvirEvent } from "type_aliases";

export type ProcoreAssigneesLoadedEvent = AvvirEvent<typeof PROCORE_ASSIGNEES_LOADED, any>

const procoreAssigneesLoaded = (procoreAssignees): ProcoreAssigneesLoadedEvent => ({
  type: PROCORE_ASSIGNEES_LOADED,
  payload: procoreAssignees
});

export const PROCORE_ASSIGNEES_LOADED = "procore_assignees_loaded";

export default procoreAssigneesLoaded;
